import { ReactNode, RefObject, useRef, useState } from 'react'
import { useObserver } from './useObserver'

type UseLazyLoaderReturn = {
    crossingElement: RefObject<HTMLDivElement>
    items: ReactNode[]
}

export const useLazyLoader = (perPage: number, items: ReactNode[]): UseLazyLoaderReturn => {
    const [page, setPage] = useState(1)
    const crossingElement = useRef<HTMLDivElement>(null)

    useObserver({
        ref: crossingElement,
        canLoad: page <= Math.floor(items.length / perPage),
        callback: () => setPage(prevPage => prevPage + 1)
    })

    return { 
        crossingElement,
        items: items.slice(0, page * perPage)
    }
}
