import { Button, Grid, IconButton, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import React, { useEffect, useState } from 'react'
import PDFViewer from '../../../components/ClinicalNotes/reusable/PDFViewer'
import AddendumListIcon from '../../../assests/images/addendum_list_icon.svg'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import RequestApprove from './requestApprove'
import { id } from 'date-fns/esm/locale'
import RepeatIcon from 'assests/icons/RepeatIcon.svg'
import open from '../../../assests/icons/open.svg'
import close from '../../../assests/icons/close.svg'
import ClinicalNoteUpdatelog from './ClinicalNoteUpdatelog'
import { ReactComponent as MessageIcon } from '../../../assests/icons/messageIcon.svg'
import CommentLog from './CommentLog'
import { userUnReadCommentApi } from '../api/services'

const useStyles = makeStyles({
    root: {
        padding: '16px 24px',
        flex: 1,
    },
    icon_back: {
        marginRight: 8,
    },
    preview_container: {
        flex: 1,
        marginTop: 16,
        borderRadius: 4,
        border: '1px solid #e8e8e8',
    },
    status_tag: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        height: 32,
        borderRadius: 4,
    },
    preview_header: {
        padding: '14px 16px',
        borderBottom: '1px solid #e8e8e8',
    },
    preview_document: {
        background: '#E9ECF1',
        padding: 24,
        flex: 1,
    },
    btn_action: {
        height: 30,
        color: '#667689',
        fontWeight: 'normal',
        margin: '0px 4px',
        background: '#F3F6F9',
    },
    comment_btn:{
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: "400",
        height: 30,
        background: '#FFF',
        borderRadius: "3px",
        border: '0.7px solid #5571C6',
        marginRight: '5px'
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approve: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_resend_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_in_progress: {
        background: '#D8D1FF',
        color: '#7340DF',
    },
    headerWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerLeft: {
        justifyContent: 'flex-start',
    },
    headerRight: {
        justifyContent: 'flex-end',
    },
    addendumsBtn: {
        backgroundColor: 'transparent',
    },
    addendumsBtnText: {
        fontSize: '14px',
        color: '#5571C6',
        marginLeft: '8px',
    },
    dot:{
        height: "10px",
        width: "10px",
        backgroundColor: "#E81A1A",
        borderRadius: "50%",
        display: "inline-block",
        bottom: "7px",
        left: "-4%",
        position: 'absolute'
      }
})

function statusMapper(status) {
    switch (status) {
        case 'PENDING_APPROVE':
            return 'Pending Approve'
        case 'DRAFT':
            return 'Draft'
        case 'LOCKED':
            return 'Locked'
        case 'RESEND_EDIT':
            return 'Resend edit'
        case 'IN_PROGRESS':
            return 'In Progress'
        default:
            return ''
    }
}

const PreviewNote = ({
    status = 'DRAFT',
    role,
    sigingAndLocking,
    onClick,
    handleSignAndLock,
    onChangeActiveView,
    PDFFile,
    handleRenderPDF,
    handleRequestApprove,
    handleResendEdit,
    isCreatorNote,
    requestingApproval,
    resendingEdit,
    approveRequestCount,
    supervisor,
    isEdit,
    clinicalNoteId,
    requestedSupervisor
}) => {
    const classes = useStyles()
    const ClinicalNotePermission = useSelector((state) => state?.permission.clinical_notes)
    const [clinicalNoteupdateLogOpen, setclinicalNoteupdateLogOpen] = useState(false)
    const [openCommentLog, setOpenCommentLog] = useState(false)
    const [unReadMessage, setUnReadMessage] = useState(false)
    role =
        role === 'bh_provider' || role === 'medical_provider'
            ? 'provider'
            : role === 'mid_bh_provider' || role === 'mid_medical_provider'
                ? 'mid_provider'
                : ''
    const handleClickCNupdateLog = () =>{
        setclinicalNoteupdateLogOpen(!clinicalNoteupdateLogOpen)
    }
    const handleClickCommentLog = () =>{
        setOpenCommentLog(true)
    }
    const handleClickCommentLogClose= () => {
        setOpenCommentLog(false)
        if(unReadMessage){
            checkUnReadComment()
        }
    }
    const checkUser = (ClinicalNotePermission.includes('resend_edit_clinical_notes') && !isCreatorNote && isEdit && status === 'PENDING_APPROVE') || (ClinicalNotePermission.includes('request_approve_clinical_notes') && supervisor?.id)
    const checkUnReadComment = async() =>{
        try{
            if(checkUser){
                const user=(ClinicalNotePermission.includes('resend_edit_clinical_notes') && !isCreatorNote && isEdit && status === 'PENDING_APPROVE') ? 'supervisor' : (ClinicalNotePermission.includes('request_approve_clinical_notes') && supervisor?.id )? "midlevel" : ""
                const payload = {
                    read_by_midlevel: user === 'midlevel' ? true : false,
                    read_by_supervisor: user === 'supervisor' ? true : false,
                }
                const res = await userUnReadCommentApi(clinicalNoteId,payload)
                setUnReadMessage(res?.data?.unread_comment_count > 0 ? true : false)
            }
        }catch (e) {
            console.log("err",e)
        }
    }
    useEffect(() => {
        checkUnReadComment()
        handleRenderPDF()
    }, [])
    return (
        <Grid className={classes.root} container onClick={onClick} direction="column">
            <Grid item container alignItems="center" style={{ marginBottom: 12 }}>
                <IconButton
                    onClick={() => onChangeActiveView('sessions')}
                    className={classes.icon_back}>
                    <KeyboardBackspaceOutlinedIcon style={{ fontSize: 16 }} />
                </IconButton>
                <Typography variant="h4" className={classes.back__btn}>
                    Back
                </Typography>
            </Grid>
            <Grid className={classes.headerWrap}>
                <div className={classes.headerLeft}>
                    <Typography variant="h4" style={{ fontSize: 22 }}>
                        Preview Note
                    </Typography>
                </div>
                <div className={classes.headerRight}>
                    {status === 'LOCKED' && (
                        <Button
                            className={classes.addendumsBtn}
                            onClick={() => onChangeActiveView('addendums')}>
                            <img
                                className={classes.addendumListIcon}
                                src={AddendumListIcon}
                                alt="Addendum List"
                            />
                            <Typography className={classes.addendumsBtnText}>
                                Addendum List
                            </Typography>
                        </Button>
                    )}
                </div>
            </Grid>
            <Grid container className={classes.preview_container} direction="column">
                <Grid
                    item
                    container
                    className={classes.preview_header}
                    justifyContent="space-between">
                    <Grid item>
                        <Grid container>
                            <Grid
                                className={clsx({
                                    [classes.status_tag]: true,
                                    [classes.status_box]: true,
                                    [classes.bgColor_draft]: status === 'DRAFT',
                                    [classes.bgColor_in_progress]: status === 'IN_PROGRESS',
                                    [classes.bgColor_pending_approve]: status === 'PENDING_APPROVE',
                                    [classes.bgColor_locked]: status === 'LOCKED',
                                    [classes.bgColor_resend_edit]: status === 'RESEND_EDIT',
                                })}
                                item>
                                {status === 'PENDING_APPROVE' ?
                                    <Typography
                                        className={clsx({
                                            [classes.bgColor_pending_approve]: status === 'PENDING_APPROVE',
                                        })}>
                                        {' '}
                                        <IconButton>
                                            <img src={RepeatIcon} alt="icon"></img>
                                        </IconButton>
                                        {' '}
                                        {`Pending Approval (${approveRequestCount})`}
                                    </Typography>
                                    :
                                    <Typography
                                        className={clsx({
                                            [classes.status_box]: true,
                                            [classes.bgColor_draft]: status === 'DRAFT',
                                            [classes.bgColor_in_progress]: status === 'IN_PROGRESS',
                                            // [classes.bgColor_pending_approve]: status === 'PENDING_APPROVE',
                                            [classes.bgColor_locked]:
                                                status === 'approved_lock' || status === 'LOCKED',
                                            [classes.bgColor_resend_edit]: status === 'RESEND_EDIT',
                                        })}>
                                        {' '}
                                        Status: {statusMapper(status) || status}
                                    </Typography>
                                }
                            </Grid>
                            <img
                                // src={superBillLogOpen ? open : close}
                                src={open}
                                alt=""
                                style={{
                                    height: '20px',
                                    paddingTop:"5px",
                                    paddingLeft: '15px',
                                    cursor: 'pointer',
                                }}
                                onClick={handleClickCNupdateLog}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                           {checkUser && <div style={{position: 'relative'}}>
                                <Button
                                    className={classes.comment_btn}
                                    startIcon={
                                            <MessageIcon thickness={2} size={15} />
                                    }
                                    onClick={handleClickCommentLog}
                                    size="small">
                                    {'Comment'}{unReadMessage ? <p className={classes.dot}></p> : false}
                                </Button>
                            </div>}
                            {ClinicalNotePermission.includes('sign_and_lock_clinical_notes') &&
                                isEdit &&
                                (status === 'PENDING_APPROVE' || status === 'DRAFT' || status === 'IN_PROGRESS') && (
                                    <Button
                                        className={classes.btn_action}
                                        startIcon={
                                            !!sigingAndLocking && (
                                                <CircularProgress thickness={2} size={15} />
                                            )
                                        }
                                        onClick={handleSignAndLock}
                                        disabled={sigingAndLocking}
                                        size="small">
                                        {'Sign & Lock'}
                                    </Button>
                                )}
                            {ClinicalNotePermission.includes('resend_edit_clinical_notes') &&
                                !isCreatorNote &&
                                isEdit &&
                                status === 'PENDING_APPROVE' && (
                                    <Button
                                        className={classes.btn_action}
                                        onClick={handleResendEdit}
                                        size="small"
                                        disabled={resendingEdit}>
                                        {'Resend edit'}
                                    </Button>
                                )}
                            {/* {ClinicalNotePermission.includes('request_approve_clinical_notes') &&
                                (status === 'DRAFT' || status === 'RESEND_EDIT' || status === 'IN_PROGRESS') && (
                                    <Button
                                        className={classes.btn_action}
                                        onClick={handleRequestApprove}
                                        size="small"
                                        disabled={requestingApproval}>
                                        {'Request approve'}
                                    </Button>
                                )} */}
                            {ClinicalNotePermission.includes('request_approve_clinical_notes') && supervisor?.id && <RequestApprove supervisor={supervisor} status={status} handleRequestApprove={handleRequestApprove} requestingApproval={requestingApproval} requestedSupervisor={requestedSupervisor} />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    className={classes.preview_document}
                    justifyContent="center"
                    alignItems="center">
                    <PDFViewer PDFFile={PDFFile} />
                </Grid>
                {clinicalNoteupdateLogOpen && (
                    <ClinicalNoteUpdatelog
                        open={clinicalNoteupdateLogOpen}
                        close={handleClickCNupdateLog}
                        id={clinicalNoteId}
                    />
                )}
            </Grid>
            {openCommentLog &&
                <CommentLog
                    open={openCommentLog}
                    close={handleClickCommentLogClose}
                    id={clinicalNoteId}
                    user={(ClinicalNotePermission.includes('resend_edit_clinical_notes') && !isCreatorNote && isEdit && status === 'PENDING_APPROVE') ? 'supervisor' : (ClinicalNotePermission.includes('request_approve_clinical_notes') && supervisor?.id )? "midlevel" : ""}
                />
            }
        </Grid>
    )
}

export default PreviewNote
