const axios = require('axios')
const { Auth } = require('aws-amplify')
const { default: store } = require('store')
const { setActiveLatest } = require('store/actions/login')
const { default: Routes } = require('constants/routes')
const { API_SYSTEM, config } = require('constants/index')

const axiosInstance = axios.create({
    baseURL: config.dev.baseURL,
})
const axiosSearchInstance = axios.create()

async function getToken() {
    const token = localStorage.getItem('token')
    if (!token) return ''

    const { activeLatest } = store.getState().login

    const payload = JSON.parse(atob(token.split('.')[1]))

    const currentTime = parseInt(new Date().getTime() / 1000)
    const { exp } = payload

    // if (activeLatest && currentTime - activeLatest > 3600) return ''
    if (exp - currentTime > 60) return token

    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })

    return user.signInUserSession.idToken.jwtToken
}

axiosInstance.interceptors.request.use(
    async (requestConfig) => {
        // const token = localStorage.getItem('token')
        // const tempToken = localStorage.getItem('tempToken')

        const token = await getToken()
        if (token) {
            let uri = ''
            const { url, baseURL } = requestConfig

            if (url.startsWith('http')) uri = url
            else uri = baseURL + url

            const { pathname } = new URL(uri)
            if (!API_SYSTEM.includes(pathname)) store.dispatch(setActiveLatest())

            localStorage.setItem('token', token)
            requestConfig.headers['Authorization'] = `Bearer ${token}`
        } else {
            store.dispatch(setActiveLatest(0))
            localStorage.clear()
            window.location.href = Routes.HOME
            return
        }

        // if (!token && !!tempToken) {
        //     requestConfig.headers['Authorization'] = `Bearer ${tempToken}`
        // } else if (!!token) {
        //     requestConfig.headers['Authorization'] = `Bearer ${token}`
        // }
        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] = requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error.response || error || new Error('Unknown error'))
    },
)

axiosSearchInstance.interceptors.request.use(
    (requestConfig) => {
        const token = process.env.REACT_APP_SONORA_SEARCH_TOKEN

        requestConfig.headers['Authorization'] = `Basic ${token}`

        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] = requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        return Promise.reject(error)
    },
)

axiosSearchInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error.response)
    },
)

export function getService(url, params = {}) {
    return axiosInstance.get(url, { params })
}

export function getServiceForBlobResponse(url, acceptableResponse) {
    return axiosInstance.get(url, {
        responseType: 'blob',
        headers: {
            Accept: acceptableResponse,
        },
    })
}

export function postSearchService(url, params) {
    return axiosSearchInstance.post(url, params)
}

export function getSearchService(url) {
    return axiosSearchInstance.get(url)
}

export function postService(url, params, options) {
    return axiosInstance.post(url, params, options)
}

export function putService(url, params) {
    return axiosInstance.request({
        url,
        method: 'PUT',
        data: params,
    })
}
export function patchService(url, params) {
    return axiosInstance.request({
        url,
        method: 'PATCH',
        data: params,
    })
}

export function deleteService(url, params) {
    return axiosInstance.request({
        url,
        method: 'DELETE',
        data: params,
    })
}
