import React from 'react'
import { makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const useStyles = makeStyles({
    encounterListWrapper: {
        width: '100%',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        height: 'calc(100% - 50px)',
        position: 'relative'
    },
    wrapList: {
        width: '85%',
        height: '100%',
    },
    listItem: {
        width: '105%',
        float: 'left',
        padding: '8px 0',
        border: '1px solid #C0C1C5',
        borderRadius: '5px',
        alignItems: 'center',
        margin: '8px 0',
    },
    avatarWrap: {
        marginTop: '-33px',
        position: 'relative',
        paddingLeft: '10px',
    },
    avatar: {
        borderRadius: '40px',
        width: '25px',
        height: '25px',
    },
    colorStatus: {
        width: '8px',
        height: '8px',
        borderRadius: '8px',
        position: 'absolute',
        border: '4px solid white',
        top: -2,
        right: 13,
    },
    infoWrap: {
        width: '100%',
    },
    btn_start: {
        background: '#397171',
        borderRadius: '8px',
        width: 'fit-content',
        fontWeight: 400,
        '&:hover': {
            background: '#397171',
        },
    },
    name: {
        color: '#303E4E',
        fontSize: 14,
        fontWeight: 500,
    },
    time: {
        color: '#303E4E',
        fontSize: '14px',
    },
    allowButton: {
        width: '68px',
        height: '32px',
        backgroundColor: '#5571C6',
        textAlign: 'center',
        color: '#fff',
        position: 'relative',
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
    },
    iconLoading: {
        position: 'absolute',
        top: 8,
        left: 4,
    },
    endButton: {
        width: '68px',
        height: '32px',
        backgroundColor: '#E81212',
        textAlign: 'center',
        color: '#fff',
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
    },
    inprogressColor: {
        backgroundColor: '#C21807',
    },
    completedColor: {
        backgroundColor: 'blue',
    },
    inWaitingRoomColor: {
        backgroundColor: 'green',
    },
    cancelledColor: {
        backgroundColor: 'gray',
    },
    noShowColor: {
        backgroundColor: 'orange',
    },
    isLateColor: {
        backgroundColor: '#81007F',
    },
    assignedToProviderColor: {
        backgroundColor: '#98FB98',
    },
    isKickedColor: {
        backgroundColor: '#4B3619',
    },
    waitingAbnomalDisconnected: {
        backgroundColor: '#808588',
    },
    examAbnomalDisconnected: {
        backgroundColor: '#808588',
    },
    placeholder: {
        color: '#737980',
    },
})


export const LightTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top-end" />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#fff',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '5px 16px',
        backgroundColor: '#fff',
        color: '#242424',
        boxShadow: '0px 4px 8px 0px #00000024',
        fontSize: 12,
    },
}))