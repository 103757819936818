import React, { ReactNode, ElementType } from 'react'
import Box from '@mui/material/Box'
import { useLazyLoader } from './useLazyLoader'

type LazyLoaderProps = {
    perPage: number,
    children: ReactNode[],
    dividerComponent?: ElementType
}

const LazyLoader = ({ perPage = 10, children = [], dividerComponent = 'div' }: LazyLoaderProps) => {
    const { crossingElement, items } = useLazyLoader(perPage, children)

    return (
        <>
            {items}
            <Box component={dividerComponent} style={{width: '1px', height: '1px' }} ref={crossingElement}></Box>
        </>
    )
}

export default LazyLoader