import React, { useEffect, useRef, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'

import ForwardIcon from 'assests/icons/ForwardIcon'
import NextIcon from 'assests/icons/NextIcon'
import Header from 'components/ClinicalNotes/behavioural/Header'
import Footer from 'components/ClinicalNotes/common/Footer'
import CustomButton from 'components/shared/Elements/CustomButton'
import LoadingPage from 'utilities/loading-page'
import { NOTE_STATUS } from '../constants'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'
import {
    fetchPDFData,
    getNotePreview,
    _updateClinicalNotes,
    getMedicalCoding,
    signAndLock,
    requestApprove,
    resendEdit,
    fetchPDFInjectionData,
    _getClinicalNotesDetail,
} from '../api/services'
import BehavioralNotesView from './BehaviouralNotes'
import CategoryNotes from './CategoryNotes'
import History from './History'
import PreviewNote from './PreviewNote'
import Addendum from '../Addendum/Addendum'
import { SET_CLINICAL_NOTES_TEMPLATE } from '../store/actionTypes'
import { useNotification } from 'shared/elements/Notification'
import { getAccountById, getScheduleById } from 'services/Calendar'
import HealthyRecord from './HealthyRecord'
import { apiVitalSigns } from 'services/Patient/apiVitalSigns'
import { useTimezone } from 'shared/hooks/useTimezone'
import * as clinicalNoteActions from '../../../store/actions/clinicalNotes'
import { sentErxUpatedTime, sendCustomEvent } from 'services/Patient/patient'
import { clinical_note_master } from '../utils'
import { useQuery } from 'shared/hooks/useQuery'
import { UserRoles } from 'constants/user-roles'
const { v4: uuidv4 } = require('uuid')

const useStyles = makeStyles({
    sessions: {
        flex: 1,
        width: 0,
        height: 'calc(100vh - 108px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
    },
    clinical_note_container: {
        // padding: '16px 20px',
        flex: 1,
    },
})


export const mapperFunction = (masterData = [], checkerData) => {
    for (let i = 0; i < checkerData.length; i++) {
        const dataheader = checkerData[i]
        const elementHeader = checkerData[i].header;
        for (let j = 0; j < masterData.length; j++) {
            const masterDataReview = masterData[j].reviews_options
            // console.log(masterDataReview, "I am master dataReview options")
            const data = masterData[j]._id;
            if (data === elementHeader) {
                dataheader.header = { _id: data, header_name: masterData[j].header_name }
                if (dataheader.reviews_options.length) {
                    for (let ik = 0; ik < dataheader.reviews_options.length; ik++) {
                        const elementUser = dataheader.reviews_options[ik];
                        for (let jk = 0; jk < masterDataReview.length; jk++) {
                            const elementMaster = masterDataReview[jk];
                            if (elementUser === elementMaster._id) {
                                dataheader.reviews_options[ik] = elementMaster

                            }

                        }

                    }
                }
            }
        }

    }
    return checkerData
}

export const devEduHistroyMapper = (orgData, chekerData) => {
    const keys = ['additional_schooling', 'childhood_atmos', 'childhood_challenges', 'difficulties_in_reaching', 'during_preg_prob', 'have_prob_in_school', 'highest_edu_level']
    for (const singleKey of keys) {
        const optionsArray = []
        if (singleKey !== 'highest_edu_level') {


            const optionArrayPull = chekerData[singleKey]?.options
            for (let cpx = 0; cpx < optionArrayPull?.length; cpx++) {
                const holdData = optionArrayPull[cpx]
                for (const singOriginal of orgData) {
                    if (singOriginal._id === holdData) {
                        optionsArray.push(singOriginal)
                    }
                }
                chekerData[singleKey].options = optionsArray
            }
        }

        if (singleKey === 'highest_edu_level') {
            const highest_edu_level_id = chekerData[singleKey]
            for (let idx = 0; idx < orgData?.length; idx++) {
                if (orgData[idx]._id === highest_edu_level_id) {
                    chekerData[singleKey] = orgData[idx]
                }

            }
        }
    }

    return chekerData
}

export const interVationMapper = (masterData = [], checkerData = []) => {
    for (let ix = 0; ix < checkerData.length; ix++) {
        const ele = checkerData[ix]?.interventions_utilized
        for (let index = 0; index < masterData.length; index++) {
            if (masterData[index]._id === ele) {
                checkerData[ix].interventions_utilized = masterData[index]
            }

        }

    }

    return checkerData

}


export const sessionMapper = (masterData = [], checkerData = []) => {
    for (let idx = 0; idx < checkerData.length; idx++) {
        const ele = checkerData[idx]
        for (let index = 0; index < masterData.length; index++) {
            if (masterData[index]._id === ele) {
                checkerData[idx] = masterData[index]
            }

        }

    }

    return checkerData

}


export const cgiMapper = (masterdata = [], checker) => {
    for (let idx = 0; idx < masterdata.length; idx++) {
        if (masterdata[idx]._id === checker?.client_condition) {
            checker.client_condition = masterdata[idx]
        }

    }
    return checker
}
export const familyEnvMapper = (masterData = [], checkerData = []) => {
    for (let ix = 0; ix < checkerData.length; ix++) {
        const ele = checkerData[ix]
        for (let index = 0; index < masterData.length; index++) {
            if (masterData[index]._id === ele) {
                checkerData[ix] = masterData[index]
            }

        }

    }

    return checkerData

}

const BehavioralNotes = (
    {
        isConsultant,
        noteDetails,
        providerType,
        state,
        setLoader,
        categoryNotes,
        clinicalNoteData,
        configureTemplateDetails,
        psycho_note,
        previous_cn,
        clinicalNoteInitialData,
        onUpdate,
    },
    props,
) => {

    const classes = useStyles()
    const {
        system_reviews,
        medication,
        exam,
        review_of_measures,
        source_of_information,
        suicide_risk_factors,
        assessment_impression,
        assessment_informed_consent,
        plan,
        plan_med_changes,
        allergy,
    } = previous_cn?.clinicalNoteModel || {}

    const notification = useNotification()
    const dispatchRedux = useDispatch()

    const clinicalNotes = useSelector((state) => state.clinicalNotes)

    const [sigingAndLocking, setSigingAndLocking] = useState(false)
    const [requestingApproval, setRequestingApproval] = useState(false)
    const [resendingEdit, setResendingEdit] = useState(false)

    const { dispatch } = React.useContext(ClinicalNoteContext)
    const submitFormRef = useRef(null)

    const defaultActiveView = 'sessions'
    const [activeNote, setActiveNote] = useState(clinicalNotes.activeNote)
    const [activeView, setActiveView] = useState(defaultActiveView)
    const [backToText, setBackToText] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [PDFFile, setPDFFile] = useState(null)

    const [role, setRole] = useState('')
    const [isCreatorNote, setIsCreatorNote] = useState(false)

    const [appointment, setAppointment] = useState({})
    const [loading, setLoading] = useState(false)
    const [upadteStatus, setUpdateStatus] = useState(null)
    const [updateCount, setUpdateCount] = useState(0)
    const [isClinicalNoteNotPresesnt, setIsClinicalNoteNotPresesnt] = useState(false)
    const [isGetClinicalNoteLoading, setIsGetClinicalNoteLoading] = useState(false)
    const userData = useSelector((state) => state.login.userData)
    const timezone = useTimezone()

    const queryString = useQuery()

    useEffect(() => {
         (async () => {
            try {
                if (noteDetails?.appointment_id) {
                    const res = await getScheduleById({
                        id: noteDetails.appointment_id,
                    })
                    setAppointment(res.data)
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    useEffect(() => {
         (async () => {
            try {
                const res = await getAccountById(userData['custom:user_id'])
                setRole(res.data.role)
                setIsCreatorNote(res.data.id == clinicalNoteData.provider_id)
            } catch (error) {
                console.log(error)
            }
        })()
    }, [userData])

    useEffect(() => {
        if (categoryNotes?.clinicalNoteTemplate)
            setCategoryList(categoryNotes.clinicalNoteTemplate.components)
    }, [categoryNotes])

    useEffect(() => {
        dispatchRedux(clinicalNoteActions.setActiveNote(activeNote))
    }, [activeNote])

    // TODO: this code will require in future, once we added error handler from backend for that reason it is commented for now 

    // useEffect(async()=>{
    //     if(clinicalNotes?.isTabChange){
    //         try{
    //             setIsGetClinicalNoteLoading(true)
    //             const res = await _getClinicalNotesDetail(noteDetails?.id,noteDetails?.name)
    //             setIsClinicalNoteNotPresesnt(false)
    //             setIsGetClinicalNoteLoading(false)
    //         }catch(err){
    //             setIsClinicalNoteNotPresesnt(true)
    //             notification('This ClinicalNote has been deleted.', 'error')
    //             setIsGetClinicalNoteLoading(false)
    //         }
    //     }

    // },[clinicalNotes?.isTabChange])

    const changeActiveNote = (val) => {
        if (
            noteDetails?.status !== 'LOCKED' && noteDetails?.name !== 'view'
            // noteDetails?.status === 'RESEND_EDIT' ||
            // noteDetails?.status === 'PENDING_APPROVE' ||
            // noteDetails?.status === 'DRAFT'
        )
            submitFormRef.current()
        setActiveNote(val)
        setActiveView(defaultActiveView)
    }
    const changeActiveView = (val) => {
        submitFormRef.current()
        setActiveView(val)
    }

    useEffect(() => {
        if(Boolean(queryString.get('notification'))) {
            submitFormRef.current = () => {}
            setActiveView('preview')
        }
    }, [])

    const checkMedicalCoding = async (appointmentId) => {
        try {
            const resp = await getMedicalCoding(appointmentId)
            const { cpt, hcpcs, icd10 } = resp.data

            if (!icd10.length)
                throw new Error('Medical Coding: Must have at least one ICD 10 Code!')
            if (hcpcs.length + cpt.length === 0)
                throw new Error('Medical Coding: Must have at least one CPT Code or HCPCS Code')
        } catch (exception) {
            setSigingAndLocking(false)
            return exception.message
        }
    }

    const checkChiefComplaint = async (clinicalNoteId) => {
        try {
            const clinicalNotesDetail = await _getClinicalNotesDetail(clinicalNoteId)
            const data = JSON.parse(clinicalNotesDetail?.data?.data)
            if (data?.clinicalNoteModel.hasOwnProperty('chief_complaint')) {
                if (data?.clinicalNoteModel?.chief_complaint.hasOwnProperty('narrative')) {
                    if (data?.clinicalNoteModel?.chief_complaint?.narrative === '') {
                        throw new Error('Chief Complain: Must have Chief Complain!')
                    }
                }else{
                    throw new Error('Chief Complain: Must have Chief Complain!')
                }
            }
        } catch (exception) {
            setSigingAndLocking(false)
            return exception.message
        }
    }

    const checkMedicalCodingForRequestapprove = async (appointmentId) => {
        try {
            const resp = await getMedicalCoding(appointmentId)
            const { cpt, hcpcs, icd10 } = resp.data

            if (!icd10.length)
                throw new Error('Medical Coding: Must have at least one ICD 10 Code!')
            if (!cpt.length)
                throw new Error('Medical Coding: Must have at least one CPT Code')

            if (cpt.length > 0) {
                for (let index = 0; index < cpt.length; index++) {
                    const item = cpt[index];

                    if (item && item.extension && item.extension.dxPointers) {
                        const dxPointersArray = item.extension.dxPointers.split(':');
                        const valueExist = dxPointersArray.some(value => parseInt(value) > 0);

                        if (!valueExist) {
                            throw new Error('Medical Coding: Must have at least one Dx Pointers in each CPT Code')
                        }
                    } else {
                        throw new Error('Medical Coding: Must have at least one Dx Pointers in each CPT Code');
                    }
                }
            }
        } catch (exception) {
            setRequestingApproval(false)
            return exception.message
        }
    }

    const handleSignAndLock = async (values) => {
        // Basecase SUAT-66
        // Medical Coding requires at least 1 ICD-10 code, and either at least one CPT code or HCPCS Code

        try {
            setLoader(true)
            setSigingAndLocking(true)
            let errorMsg = null
            if(clinicalNoteData?.template_id === "therapy_progress_note" || clinicalNoteData?.template_id === "therapy_initial_note"){
                errorMsg = await checkMedicalCodingForRequestapprove(clinicalNoteData.appointment_id)
            }else{
                errorMsg = await checkMedicalCoding(clinicalNoteData.appointment_id)
            }
            if (errorMsg) {
                return notification(errorMsg, 'error')
            }
            const err = await checkChiefComplaint(clinicalNoteData.id)
            if (err) {
                return notification(err, 'error')
            }
            const filterByTemplate = { ...values }
            state.clinicalNoteTemplate.forEach((item) => {
                if (!item.isShow) {
                    delete filterByTemplate[item.componentId]
                }
            })


            const cloneCategoryNotes = _.cloneDeep(filterByTemplate)
            if (cloneCategoryNotes.system_reviews) {
                const systemReview = mapperFunction(clinical_note_master.systemReviewMasters, cloneCategoryNotes.system_reviews.system_reviews_opt)
                cloneCategoryNotes.system_reviews['system_reviews_opt'] = systemReview
            }
            if (cloneCategoryNotes?.dev_and_edu_history) {
                const devEduHistry = devEduHistroyMapper(clinical_note_master.devEduHistoryMasters, cloneCategoryNotes.dev_and_edu_history)
                cloneCategoryNotes.dev_and_edu_history = devEduHistry
            }
            if (cloneCategoryNotes?.family_history) {

                if (!cloneCategoryNotes?.family_history?.psych_illness_history) {
                    cloneCategoryNotes.family_history.history_list = []
                }
            }
            if (cloneCategoryNotes?.interven_utilized) {
                const intervationMapperprepare = interVationMapper(clinical_note_master.interventionMasters, cloneCategoryNotes.interven_utilized.interventions)
                cloneCategoryNotes.interven_utilized['interventions'] = intervationMapperprepare
            }

            if (cloneCategoryNotes?.session_focus) {
                const sessionmapperPrepare = sessionMapper(clinical_note_master.sessionFocusMasters, cloneCategoryNotes.session_focus.session)
                cloneCategoryNotes.session_focus['session'] = sessionmapperPrepare
            }

            if (cloneCategoryNotes?.clinical_global_impression) {
                const cgi = cgiMapper(clinical_note_master.clinicalImpMasters, cloneCategoryNotes.clinical_global_impression)
                cloneCategoryNotes.clinical_global_impression = cgi
            }

            if (cloneCategoryNotes?.family_env_strategy) {
                const familyEnvMapperPrepare = familyEnvMapper(clinical_note_master.envStrategyMasters, cloneCategoryNotes?.family_env_strategy.fam_env_strategy)
                cloneCategoryNotes.family_env_strategy['fam_env_strategy'] = familyEnvMapperPrepare

            }

            await signAndLock(clinicalNoteData.id, {
                timezone: timezone.locationTimezone,
                label_timezone: timezone.labelTimezone,
                clinicalNoteData: {
                    ...categoryNotes,
                    clinicalNoteModel: cloneCategoryNotes,
                }
            })
            Swal.fire('', 'Note has been successfully signed and locked')
            await onUpdate("LOCKED")
        } catch (error) {
            notification('Something went wrong. Please try again.', 'error')
        } finally {
            setLoader(false)
            setSigingAndLocking(false)
        }
    }

    const handleRequestApprove = async (id) => {
        try {
            setLoader(true)
            setRequestingApproval(true)

            const errorMsg = await checkMedicalCodingForRequestapprove(clinicalNoteData.appointment_id)
            if (errorMsg) {
                return notification(errorMsg, 'error')
            }
            const err = await checkChiefComplaint(clinicalNoteData.id)
            if (err) {
                return notification(err, 'error')
            }
            await requestApprove(clinicalNoteData.id, {
                timezone: timezone.locationTimezone,
                label_timezone: timezone.labelTimezone,
                supervisor_id: id
            })
            Swal.fire('', 'Note has been successfully requested for approval')
            await onUpdate()
        } catch (err) {
            notification(err?.data?.message || 'Something went wrong. Please try again.', 'error')
        } finally {
            setLoader(false)
            setRequestingApproval(false)
        }
    }

    const handleResendEdit = async () => {
        try {
            setLoader(true)
            setResendingEdit(true)

            await resendEdit(clinicalNoteData.id)
            Swal.fire('', 'Resend note to edit successfully')
            await onUpdate()
        } catch (err) {
            notification('Something went wrong. Please try again.', 'error')
        } finally {
            setLoader(false)
            setResendingEdit(false)
        }
    }



    const handleRenderPDF = (values) => {
        if (values) {
            setPDFFile(null)
            const filterByTemplate = { ...values }
            state.clinicalNoteTemplate.forEach((item) => {
                if (!item.isShow) {
                    delete filterByTemplate[item.componentId]
                }
            })

            const cloneCategoryNotes = _.cloneDeep(filterByTemplate)

            if (cloneCategoryNotes.system_reviews) {
                const systemReview = mapperFunction(clinical_note_master.systemReviewMasters, cloneCategoryNotes.system_reviews.system_reviews_opt)
                cloneCategoryNotes.system_reviews['system_reviews_opt'] = systemReview
            }

            if (cloneCategoryNotes?.dev_and_edu_history) {
                const devEduHistry = devEduHistroyMapper(clinical_note_master.devEduHistoryMasters, cloneCategoryNotes.dev_and_edu_history)
                cloneCategoryNotes.dev_and_edu_history = devEduHistry
            }
            if (cloneCategoryNotes?.family_history) {

                if (!cloneCategoryNotes?.family_history?.psych_illness_history) {
                    cloneCategoryNotes.family_history.history_list = []
                }
            }

            if (cloneCategoryNotes?.interven_utilized) {
                const intervationMapperprepare = interVationMapper(clinical_note_master.interventionMasters, cloneCategoryNotes.interven_utilized.interventions)
                cloneCategoryNotes.interven_utilized['interventions'] = intervationMapperprepare
            }

            if (cloneCategoryNotes?.session_focus) {
                const sessionmapperPrepare = sessionMapper(clinical_note_master.sessionFocusMasters, cloneCategoryNotes.session_focus.session)
                cloneCategoryNotes.session_focus['session'] = sessionmapperPrepare
            }

            if (cloneCategoryNotes?.clinical_global_impression) {
                const cgi = cgiMapper(clinical_note_master.clinicalImpMasters, cloneCategoryNotes.clinical_global_impression)
                cloneCategoryNotes.clinical_global_impression = cgi
            }

            if (cloneCategoryNotes?.family_env_strategy) {
                const familyEnvMapperPrepare = familyEnvMapper(clinical_note_master.envStrategyMasters, cloneCategoryNotes?.family_env_strategy.fam_env_strategy)
                cloneCategoryNotes.family_env_strategy['fam_env_strategy'] = familyEnvMapperPrepare

            }
            // _updateClinicalNotes(clinicalNoteData.id, {
            //     ...clinicalNoteData,
            //     data: JSON.stringify({
            //         ...categoryNotes,
            //         clinicalNoteModel: values,
            //     }),
            // })

            // const fetchDataFunction = clinicalNoteData?.template_id === "injection_note" ? fetchPDFInjectionData : fetchPDFData;
            //return 
            fetchPDFData(clinicalNoteData.id, {
                ...categoryNotes,
                clinicalNoteModel: cloneCategoryNotes,
            }).then((response) => {
                const url = URL.createObjectURL(response.data)
                setPDFFile(url)
            })
        }
    }

    useEffect(() => {
        if (activeView === 'preview') {
            handleRenderPDF()
        }
    }, [activeView])

    const handlePreview = () => {
        const onPreview = async () => {
            try {
                setLoader(true)
                const result = await getNotePreview({
                    note_type: noteDetails?.note_type,
                    note_id: noteDetails?.note_id,
                })
                const blob = new Blob([result.data], {
                    type: 'application/pdf',
                })
                setLoader(false)
                const link = window.URL.createObjectURL(blob)
                window.open(link)
            } catch (err) {
                setLoader(false)
                notification('Something went wrong. Please try again.', 'error')
            }
        }
        onPreview()
    }

    const handleGotoSession = (type, values) => {
        if (noteDetails?.name !== 'view') {
            submitFormRef.current()
        }

        const activeNoteIndex = categoryList.findIndex(
            (item) => item.componentId === activeNote.componentId,
        )
        if (
            activeNoteIndex >= 0 &&
            activeNoteIndex <= categoryList.length - 1 &&
            categoryList[activeNoteIndex + type]
        ) {
            setActiveNote(categoryList[activeNoteIndex + type])
        }
    }

    const isLastSession = () => {
        const activeNoteIndex = categoryList.findIndex(
            (item) => item.componentId === activeNote.componentId,
        )
        return activeNoteIndex === categoryList.length - 1
    }

    const isFirstSession = () => {
        const activeNoteIndex = categoryList.findIndex(
            (item) => item.componentId === activeNote.componentId,
        )
        return activeNoteIndex === 0
    }

    const saveForceFullyClinicalNote = async () => {
        setLoading(true)
        let converToEST = ""
        const startAppointmentDate = moment(clinicalNoteData?.appointment_start).utc()
        if (moment().tz('America/New_York').isDST()) {
            converToEST = startAppointmentDate.subtract(240, 'minute').format('MM/DD/YYYY HH:mm:ss')
        } else {
            converToEST = startAppointmentDate.subtract(300, 'minute').format('MM/DD/YYYY HH:mm:ss')
        }
        if (activeNote?.componentId === 'plan_med_changes') {
            dispatchRedux(clinicalNoteActions.updatingMedicationList(true))
        }
        if (activeNote?.componentId === "medical_coding") {
            dispatchRedux(clinicalNoteActions.updatingMedicalCoding(true))
        }
        submitFormRef.current()
        
        try {   
            await sentErxUpatedTime(clinicalNoteData?.patient_id, converToEST)
        } catch (error) {
            notification('Something went wrong. Please try again later', 'error')
            throw new Error(error.data.message)
        }
    }
    const handleChangeTemplate = (newTemplates) => {
        setCategoryList([])

        dispatch({
            type: SET_CLINICAL_NOTES_TEMPLATE,
            value: state.clinicalNoteTemplate.map((item) => {
                const sessionCompare = newTemplates.find(
                    (session) => session.componentId === item.componentId,
                )
                return {
                    ...item,
                    isShow: sessionCompare ? sessionCompare.isShow : item.isShow,
                }
            }),
        })
    }

    const getData = (key, value) => {
        return state.clinicalNoteTemplate.find((item) => item.componentId === key)?.status ===
            'completed' || !Boolean(value)
            ? categoryNotes.clinicalNoteModel?.[key]
            : { ...value, narrative: '' }
    }
    useEffect(() => {
        if (loading) {
            if (upadteStatus?.status === 200) {
                notification(upadteStatus?.data?.message || "Clinical note updated successfully.", 'success')
            } else {
                notification('Something went wrong. Please try again.', 'error')
            }
        }
    }, [upadteStatus])
    const checkEditOrNot = () =>{
        if(userData?.role_code === "medical_assistant"){
            const editOrNot = [
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(clinicalNoteData.status) ||
            noteDetails?.name === 'view' ||
            (clinicalNoteData?.in_edit) ||
            (clinicalNoteData.status === 'resent_to_edit' && role === UserRoles.PROVIDER) ||
            (isClinicalNoteNotPresesnt === true)
            return editOrNot 
        }
            const editOrNot = [
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(clinicalNoteData.status) ||
                noteDetails?.name === 'view' ||
                !(clinicalNoteData?.isEditable) ||
                (clinicalNoteData?.in_edit) ||
                (clinicalNoteData.status === 'resent_to_edit' && role === UserRoles.PROVIDER) ||
                (isClinicalNoteNotPresesnt === true)
            return editOrNot
    }
    const nonEditable = checkEditOrNot()

    return (
        <>
            <Formik
                initialValues={{
                    ...categoryNotes.clinicalNoteModel,
                    // system_reviews: getData('system_reviews', system_reviews), //SOI-307
                    medication: getData('medication', medication),
                    // exam: getData('exam', exam),
                    review_of_measures: getData('review_of_measures', review_of_measures),
                    // source_of_information: getData('source_of_information', source_of_information), //SOI-305
                    // suicide_risk_factors: getData('suicide_risk_factors', suicide_risk_factors),
                    assessment_impression: getData('assessment_impression', assessment_impression),
                    // assessment_informed_consent: getData(
                    //     'assessment_informed_consent',
                    //     assessment_informed_consent,
                    // ), //SOI-301
                    // plan: getData('plan', plan), //SOI-302
                    // plan_med_changes: getData('plan_med_changes', plan_med_changes),
                    allergy: getData('allergy', allergy),
                }}
                validate={async (values) => {
                    if (nonEditable || isGetClinicalNoteLoading) return
                    if (clinicalNoteData.status === 'DRAFT' || clinicalNoteData.status === 'RESEND_EDIT') {
                        clinicalNoteData.status = "IN_PROGRESS"
                    }
                    const newcount = updateCount+1
                    const res = await _updateClinicalNotes(clinicalNoteData.id, {
                        ...clinicalNoteData,
                        data: JSON.stringify({
                            ...categoryNotes,
                            clinicalNoteModel: values,
                            clinicalNoteTemplate: {
                                ...categoryNotes.clinicalNoteTemplate,
                                components: state.clinicalNoteTemplate,
                            },
                        }),
                        tracking_info: {
                            seEventUuid: uuidv4(),
                            seSaveCount: newcount,
                            seClinicalNoteSection: activeNote.componentId
                        }
                    })
                    setUpdateCount(newcount)
                    setUpdateStatus(res)
                    setLoading(false)
                    if (values?.vitals?.current.length > 0 && activeNote?.componentId === 'vitals') {
                        const data = values.vitals?.current.map(({ value = '', unit, label }) => {
                            const converter = {
                                Temperature: {
                                    unit: '[degF]',
                                    code: '[degF]',
                                    value,
                                },
                                'Blood Pressure': {
                                    unit: 'mm[Hg]',
                                    code: 'mm[Hg]',
                                    systolicValue: value?.split?.('/')?.[0] || '',
                                    diastolicValue: value?.split?.('/')?.[1] || '',
                                },
                                'Pulse Ox': {
                                    unit: '%',
                                    code: '%',
                                    value,
                                },
                                'Respiratory Rate': {
                                    unit: '/min',
                                    code: '/min',
                                    value,
                                },
                                Height: {
                                    unit: 'cm',
                                    code: 'cm',
                                    value: (() => {
                                        switch (unit) {
                                            case 'ft/in':
                                                const values = value?.split?.('/')
                                                const ftValue = values?.[0]
                                                    ? values?.[0] * 30.48
                                                    : ''
                                                const inValue = values?.[1]
                                                    ? values?.[1] * 2.54
                                                    : ''

                                                let sum = 0
                                                if (ftValue && inValue) {
                                                    sum = ftValue + inValue
                                                } else if (ftValue) {
                                                    sum = ftValue
                                                } else if (inValue) {
                                                    sum = inValue
                                                }
                                                return sum ? parseFloat(sum).toFixed(2) : ''
                                            default:
                                                return value && (value * 2.54).toFixed(2)
                                        }
                                    })(),
                                },
                                Weight: {
                                    unit: 'kg',
                                    code: 'kg',
                                    value: (() => {
                                        switch (unit) {
                                            case 'lb':
                                                return value && (value * 0.45359237).toFixed(2)
                                            default:
                                                return value
                                        }
                                    })(),
                                },
                                BMI: {
                                    unit: 'kg/m2',
                                    code: 'kg/m2',
                                    value: value || '',
                                },
                                'Heart Rate': {
                                    unit: 'beats/minute',
                                    code: 'beats/minute',
                                    value,
                                }
                            }

                            return converter[label]
                        })
                        const isEmpty = (obj, otherLabel) =>
                            !Boolean(obj?.value === undefined ? obj[otherLabel] : obj?.value)
                        if (
                            isEmpty(data[1], 'diastolicValue') &&
                            isEmpty(data[1], 'systolicValue') &&
                            isEmpty(data[0]) &&
                            isEmpty(data[2]) &&
                            isEmpty(data[3]) &&
                            isEmpty(data[4]) &&
                            isEmpty(data[5]) &&
                            isEmpty(data[6]) &&
                            isEmpty(data[7])
                        )
                            return
                        const vitalSigns = {
                            patient_id: clinicalNoteData?.patient?.id,
                            encounter_id: clinicalNoteData?.encounter_id,
                            temperature: data[0],
                            blood_pressure: data[1],
                            pulse_oximetry: data[2],
                            respiratory_rate: data[3],
                            height: data[4],
                            weight: data[5],
                            bmi: data[6],
                            heart_rate: data[7],
                        }
                        await apiVitalSigns.create(vitalSigns)
                    }
                }}>
                {({ values, setFieldValue, actions, isValid }) => {
                    return (
                        <Form
                            style={{
                                width: '100%',
                                flex: 1,
                            }}>
                            {loading && <LoadingPage />}
                            {state.loading ? (
                                <LoadingPage />
                            ) : (
                                <Grid
                                    container
                                    wrap={'nowrap'}
                                    className={classes.clinical_note_container}>
                                    <CategoryNotes
                                        clinicalNoteData={clinicalNoteData}
                                        configureTemplateDetails={configureTemplateDetails}
                                        handleChangeTemplate={handleChangeTemplate}
                                        categoryList={state.clinicalNoteTemplate}
                                        categoryNotes={categoryNotes}
                                        activeNote={activeNote}
                                        onChangeActiveNote={changeActiveNote}
                                        onChangeActiveView={changeActiveView}
                                        clinicalNoteId={clinicalNoteData.id}
                                    />
                                    <Grid
                                        container
                                        direction="column"
                                        className={classes.sessions}
                                        wrap="nowrap">
                                        {activeView === 'sessions' && (
                                            <>
                                                <BehavioralNotesView
                                                    handleSetViewHealthyRecord={(value, text) => {
                                                        setActiveView(`healthy_record.${  value}`)
                                                        setBackToText(text)
                                                    }}
                                                    status={clinicalNoteData.status}
                                                    role={role}
                                                    noteDetails={noteDetails}
                                                    state={state}
                                                    values={values}
                                                    initialValues={clinicalNoteInitialData}
                                                    activeNote={activeNote}
                                                    onChange={setFieldValue}
                                                    handlePreview={handlePreview}
                                                    submitFormRef={submitFormRef}
                                                    psycho_note={psycho_note}
                                                    previous_cn={previous_cn}
                                                    appointmentId={clinicalNoteData.appointment_id}
                                                    fetchClinicalNote={onUpdate}
                                                    clinicalNoteData={clinicalNoteData}
                                                    appointmentDetails={appointment}
                                                    isClinicalNoteNotPresesnt={isClinicalNoteNotPresesnt}
                                                />
                                                {activeNote?.componentId &&
                                                    clinicalNoteData.status !== 'LOCKED' && (
                                                        <Footer
                                                            providerType={providerType}
                                                            loggedInProvider={
                                                                state.loggedInProvider
                                                            }>
                                                            {/* {renderSaveDraftButton(
                                                            handleSave,
                                                            values,
                                                            actions,
                                                            NOTE_STATUS.DRAFT,
                                                        )} */}
                                                            <Grid
                                                                container
                                                                justifyContent={
                                                                    isFirstSession()
                                                                        ? 'flex-end'
                                                                        : 'space-between'
                                                                }>
                                                                {!isFirstSession() && (
                                                                    <CustomButton
                                                                        startIcon={<ForwardIcon />}
                                                                        // disabled={!isValid}
                                                                        type="button"
                                                                        onClick={() => {
                                                                            handleGotoSession(
                                                                                -1,
                                                                                values,
                                                                            )
                                                                        }}
                                                                        label="Previous"
                                                                        variant="outlined"
                                                                        color="white"
                                                                    />
                                                                )}
                                                                {!isLastSession() && (
                                                                    <CustomButton
                                                                        endIcon={<NextIcon />}
                                                                        // disabled={!isValid}
                                                                        type="button"
                                                                        onClick={() => {
                                                                            handleGotoSession(
                                                                                1,
                                                                                values,
                                                                            )
                                                                        }}
                                                                        label="Next"
                                                                        variant="outlined"
                                                                        color="white"
                                                                    />
                                                                )}
                                                            </Grid>
                                                        </Footer>
                                                    )}
                                            </>
                                        )}

                                        {activeView === 'history' && (
                                            <History
                                                patientId={
                                                    clinicalNoteData.patient_id ||
                                                    clinicalNoteData?.patient?.id
                                                }
                                                onChangeActiveView={changeActiveView}
                                            />
                                        )}
                                        {activeView === 'preview' && (
                                            <PreviewNote
                                                role={role}
                                                status={clinicalNoteData.status}
                                                PDFFile={PDFFile}
                                                sigingAndLocking={sigingAndLocking}
                                                requestingApproval={requestingApproval}
                                                resendingEdit={resendingEdit}
                                                handleRenderPDF={() => {
                                                    handleRenderPDF(values)
                                                }}
                                                handleSignAndLock={() => {
                                                    handleSignAndLock(values)
                                                }}
                                                onChangeActiveView={changeActiveView}
                                                handleRequestApprove={handleRequestApprove}
                                                handleResendEdit={handleResendEdit}
                                                isCreatorNote={isCreatorNote}
                                                approveRequestCount={clinicalNoteData?.approveRequestCount}
                                                supervisor={clinicalNoteData?.supervisor}
                                                isEdit={clinicalNoteData?.isEditable}
                                                clinicalNoteId={clinicalNoteData.id}
                                                requestedSupervisor={clinicalNoteData?.requested_provider_to_approve}
                                            />
                                        )}
                                        {activeView === 'addendums' && (
                                            <Addendum
                                                clinicalNoteData={clinicalNoteData}
                                                onChangeActiveView={changeActiveView}
                                                categoryNotes={categoryNotes}
                                                value={values}
                                            />
                                        )}
                                        {activeView.includes('healthy_record') && (
                                            <HealthyRecord
                                                backToText={backToText}
                                                patientId={clinicalNoteData?.patient?.id}
                                                encounter_id={psycho_note?.encounter_id}
                                                activeView={activeView.split('.')[1]}
                                                onChangeActiveView={changeActiveView}
                                            />
                                        )}
                                    </Grid>
                                    {!isEmpty(clinicalNoteData.patient) && (
                                        <Header
                                            noteDetails={noteDetails}
                                            isConsultant={isConsultant}
                                            clinicalNoteData={clinicalNoteData}
                                            providerDetail={state.providerDetail}
                                            onChangeActiveView={changeActiveView}
                                            appointmentDetails={appointment}
                                            activeView={activeView}
                                            activeNote={activeNote}
                                            onChange={setFieldValue}
                                            handleSetViewHealthyRecord={(value) =>
                                                setActiveView(`healthy_record.${  value}`)
                                            }
                                            saveClinicalNote={saveForceFullyClinicalNote}
                                            loading={loading}
                                            sendCustomEvent={sendCustomEvent}
                                            isClinicalNoteNotPresesnt={isClinicalNoteNotPresesnt}
                                        />
                                    )}

                                    {/* <ConsultationList /> */}
                                </Grid>
                            )}
                        </Form>
                    )
                }}
            </Formik >
        </>
    )
}

export default BehavioralNotes
