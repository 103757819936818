import React, { memo, useCallback, ReactNode } from 'react'
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    Typography,
} from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import {
    ENCOUNTER_QUEUE_PATIENT_STATUS,
    ENCOUNTER_QUEUE_PATIENT_STATUS_LIST,
} from '../../constant'
import MapIcon from '../../../../assests/icons/map-icon.svg'
import TeleHealthIcon from '../../../../assests/icons/telehealth-icon.svg'
import ProviderIcon from '../../../../assests/icons/provider-icon.svg'
import BuildingIcon from '../../../../assests/icons/building-icon.svg'
import PlayIcon from '../../../../assests/icons/play-icon.svg'
import JoinEncIcon from '../../../../assests/icons/join-enc-icon.svg'
import EndEncIcon from '../../../../assests/icons/end-enc-icon.svg'
import LeaveEncIcon from '../../../../assests/icons/leave-enc-icon.svg'
import TargetIcon from '../../../../assests/images/target_icon.svg'
import Button from '@mui/material/Button'
import { ReactComponent as NoClinicalNoteIcon } from '../../../../assests/icons/NoClinicalNote.svg'
import ProviderLate from '../../../../assests/icons/ProviderLate.svg'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import SpinnerIcon from '../../../../assests/icons/SpinnerIcon'
import ProviderLateActive from '../../../../assests/icons/ProviderLateActive.svg'
import { shortenString } from '../../utils'
import { ButtonSpinnerEncounter } from '../../../../components/custom'
import {
    handleOpenSaveAndCloseDialog,
    handleSetCurrentEncounter,
} from 'store/actions/clinicalNotes'
import { useDispatch } from 'react-redux'
import { useStyles, LightTooltip } from './styles'
import { formatTime, renderEncounterQueuePatientStatusValue } from './helpers'

const Placeholder = memo(({ children }: { children: ReactNode } ) => {
    const classes = useStyles()
    return <div className={classes.placeholder}>{children}</div>
})

const EncounterItem = ({
    index,
    item, 
    loadingApi, 
    encounterId, 
    markedProviderGettingLate,
    handleOpenAlertPopup,
    shedulePermission,
    handleEncounterCardStatus,
    telehealthScreen,
    selectedEncounter,
    saveAndCloseDialog,
    loading,
    handleEndClick,
    handleStartEncounter,
    handleLeaveEncounter,
    isEndBtn,
    handleJoinEncounter,
    isQuitBtn,
    refetchEncounters
}: any) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const renderEncounterQueuePatientStatus = useCallback((status) => {
        if (status === '' || !status) {
            return <Placeholder> ----- </Placeholder>
        }
        return ENCOUNTER_QUEUE_PATIENT_STATUS_LIST.find((element) => element.value === status)
            ?.label
    }, [])

    return  <ListItem
    id={`${index}`}
    className={classes.listItem}>
    <div
        className="list_holder"
        key={`list_holder_${index}`}>
        <ListItemAvatar
            className={classes.avatarWrap}
            key={`avatarWrap_${index}`}>
            <div key={`avatarWrap_indiv_${index}`}>
                <Avatar
                    variant="square"
                    className={classes.avatar}
                    key={`avatar_wrap_1_${index}`}></Avatar>
                {/* <StatusBadge
                    appointmentStatusId={
                        item.appointment_status_id
                    }
                    appointmentId={item.appointment_id}
                /> */}
                <div
                    className={`status_dot ${
                        item.CARD_STATUS ===
                        ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED
                            ? 'arrived'
                            : item.CARD_STATUS ===
                              ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION
                            ? 'session'
                            : item.CARD_STATUS ===
                              ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW
                            ? 'no_show'
                            : item.CARD_STATUS ===
                              ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED
                            ? 'complete'
                            : ''
                    }`}
                    key={`status_dot_1_${index}`}>
                    {item.CARD_STATUS ===
                    ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED ? (
                        <img src={MapIcon}></img>
                    ) : item.CARD_STATUS ===
                      ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION ? (
                        <span>&#8722;</span>
                    ) : item.CARD_STATUS ===
                      ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW ? (
                        <span>&#10005;</span>
                    ) : item.CARD_STATUS ===
                      ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED ? (
                        <img src={TargetIcon}></img>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </ListItemAvatar>
        {item.CARD_STATUS ===
            ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION && (
            <div
                style={{
                    position: 'absolute',
                    bottom: '7px',
                    left: '15px',
                    fontSize: 'small',
                    color: `${
                        item.duration > 0
                            ? '#15A452'
                            : '#E81A1A'
                    }`,
                }}
                key={`in_session_time_div_${index}`}>
                {formatTime(item.duration)}
            </div>
        )}
        <div
            style={{
                position: 'absolute',
                bottom: '2px',
                left: '23px',
                fontSize: 'small',
            }}>
            <HtmlTooltip
                arrow
                title="Tell patient provider is running behind"
                placement="bottom-start">
                <div style={{ position: 'relative' }}>
                    {(item?.CARD_STATUS === 'NOT_START' ||
                        item?.CARD_STATUS === 'ARRIVED') &&
                        !Boolean(
                            item?.is_provider_late,
                        ) && (
                            <img
                                src={ProviderLate}
                                alt=""
                                onClick={() => {
                                    markedProviderGettingLate(
                                        item?.appointment_id,
                                        item.encounter_id,
                                    )
                                }}
                                style={{ height: '19px' }}
                            />
                        )}
                    {loadingApi &&
                        encounterId ===
                            item.encounter_id && (
                            <SpinnerIcon
                                style={{
                                    position: 'absolute',
                                    bottom: '2px',
                                    left: '-1px',
                                }}
                            />
                        )}
                </div>
            </HtmlTooltip>
            {(item?.CARD_STATUS === 'NOT_START' ||
                item?.CARD_STATUS === 'ARRIVED') &&
                Boolean(item?.is_provider_late) && (
                    <img
                        src={ProviderLateActive}
                        alt=""
                        style={{ height: '19px' }}
                    />
                )}
        </div>

        <div
            className={classes.infoWrap}
            key={`div_infoWrap_1_${index}`}>
            <Typography
                className={classes.name}
                key={`enc_typo_1_${index}`}>{`${
                item.patient?.first_name || ''
            } ${
                item.patient?.last_name || ''
            }`}</Typography>
            <div className="inline_line_holder">
                <Typography
                    className={classes.time}
                    key={`enc_typo_2_${index}`}
                    style={{ paddingRight: '3px' }}>
                    {moment(item.start).format('hh:mm A')}
                </Typography>
                <div
                    className="onboard_status"
                    key={`div_onboard_status_1_${index}`}>
                    <p
                        className="each_holder"
                        key={`onbstatus_each_holder_1_${index}`}>
                        {item.practitioner_last_name && (
                            <>
                                <img
                                    src={ProviderIcon}
                                    height="12px"
                                    width="11px"
                                />
                                {item.practitioner_last_name
                                    .length < 12 && (
                                    <span className="name">
                                        {
                                            item.practitioner_last_name
                                        }
                                    </span>
                                )}
                                {item.practitioner_last_name
                                    .length > 12 && (
                                    <HtmlTooltip
                                        title={`${item.practitioner_first_name} ${item.practitioner_last_name} `}
                                        arrow
                                        placement="top-start"
                                        style={{
                                            background:
                                                'white !important',
                                        }}>
                                        <span className="name">
                                            {shortenString(
                                                item.practitioner_last_name,
                                                10,
                                            )}
                                        </span>
                                    </HtmlTooltip>
                                )}
                            </>
                        )}
                    </p>
                </div>
            </div>
            <div
                className="inline_line_holder"
                key={`inline_line_holder_1_${index}`}>
                <Select
                    id="request-type"
                    className="ba-input-fields select_without_pd"
                    name={'request-type'}
                    disableUnderline
                    placeholder={'status'}
                    value={renderEncounterQueuePatientStatusValue(item.CARD_STATUS)}
                    displayEmpty
                    disabled={
                        shedulePermission.includes(
                            'modify_encounter_status',
                        )
                            ? item.CARD_STATUS === 'NO_SHOW'
                                ? true
                                : false
                            : true
                    }
                    renderValue={() => {
                        return item.CARD_STATUS ? (
                            renderEncounterQueuePatientStatus(item.CARD_STATUS,)
                        ) : (
                            <Placeholder>{' '}-----{' '}</Placeholder>
                        )
                    }}
                    onChange={(event) => {
                        event.target.value === 'NO_SHOW'
                            ? handleOpenAlertPopup(
                                  event,
                                  item,
                                  index,
                              )
                            : item?.appointment_type !==
                              'Telehealth Visit'
                            ? handleEncounterCardStatus(
                                  item,
                                  event.target.value,
                              )
                            : ''
                    }}
                    key={`request_type_select_1_${index}`}>
                    {ENCOUNTER_QUEUE_PATIENT_STATUS_LIST.map(
                        (el) => {
                            if (
                                item?.appointment_type ===
                                'Telehealth Visit'
                            ) {
                                // if (
                                //     el?.label !==
                                //     'In Session' &&
                                //     el?.label !== 'Arrived'
                                // )
                                return (
                                    <MenuItem
                                        value={el.value}
                                        key={el.value}
                                        className="pl-select-option icon_list">
                                        <div
                                            className={`status_holder ${
                                                el?.label ===
                                                'Arrived'
                                                    ? 'arrived'
                                                    : el?.label ===
                                                      'In Session'
                                                    ? 'session'
                                                    : el?.label ===
                                                      'No Show'
                                                    ? 'no_show'
                                                    : el?.label ===
                                                      '------'
                                                    ? 'no_start'
                                                    : el?.label ===
                                                      'Complete'
                                                    ? 'complete'
                                                    : ''
                                            }`}>
                                            {el?.label ===
                                            'Arrived' ? (
                                                <img
                                                    src={
                                                        MapIcon
                                                    }></img>
                                            ) : el?.label ===
                                              'In Session' ? (
                                                <span>
                                                    &#8722;
                                                </span>
                                            ) : el?.label ===
                                              'No Show' ? (
                                                <span>
                                                    &#10005;
                                                </span>
                                            ) : el?.label ===
                                              '------' ? (
                                                <span>
                                                    --------
                                                </span>
                                            ) : el?.label ===
                                              'Complete' ? (
                                                <img
                                                    src={
                                                        TargetIcon
                                                    }></img>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {el?.label !==
                                            '------' &&
                                            el?.label}
                                    </MenuItem>
                                )
                            }
                            if (el?.label !== 'In Session')
                                return (
                                    <MenuItem
                                        value={el.value}
                                        key={el.value}
                                        className="pl-select-option icon_list">
                                        <div
                                            className={`status_holder ${
                                                el?.label ===
                                                'Arrived'
                                                    ? 'arrived'
                                                    : el?.label ===
                                                      'In Session'
                                                    ? 'session'
                                                    : el?.label ===
                                                      'No Show'
                                                    ? 'no_show'
                                                    : el?.label ===
                                                      '------'
                                                    ? 'no_start'
                                                    : el?.label ===
                                                      'Complete'
                                                    ? 'complete'
                                                    : ''
                                            }`}>
                                            {el?.label ===
                                            'Arrived' ? (
                                                <img
                                                    src={
                                                        MapIcon
                                                    }></img>
                                            ) : el?.label ===
                                              'In Session' ? (
                                                <span>
                                                    &#8722;
                                                </span>
                                            ) : el?.label ===
                                              'No Show' ? (
                                                <span>
                                                    &#10005;
                                                </span>
                                            ) : el?.label ===
                                              '------' ? (
                                                <span>
                                                    --------
                                                </span>
                                            ) : el?.label ===
                                              'Complete' ? (
                                                <img
                                                    src={
                                                        TargetIcon
                                                    }></img>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {el?.label !==
                                            '------' &&
                                            el?.label}
                                    </MenuItem>
                                )
                        },
                    )}
                </Select>

                <div
                    className="onboard_status"
                    key={`div_onboard_status_1_${index}`}>
                    {/* <p className="each_holder" key={`onbstatus_each_holder_1_${index}`}>
                        {item.practitioner_last_name && (
                            <>
                                <img
                                    src={ProviderIcon}
                                    height="12px"
                                    width="11px"
                                />
                                {item.practitioner_last_name
                                    .length < 12 && (
                                        <span className="name">
                                            {
                                                item.practitioner_last_name
                                            }
                                        </span>
                                    )}
                                {item.practitioner_last_name
                                    .length > 12 && (
                                        <HtmlTooltip
                                            title={`${item.practitioner_first_name} ${item.practitioner_last_name} `}
                                            arrow
                                            placement="top-start"
                                            style={{
                                                background:
                                                    'white !important',
                                            }}>
                                            <span className="name">
                                                {shortenString(
                                                    item.practitioner_last_name,
                                                    10,
                                                )}
                                            </span>
                                        </HtmlTooltip>
                                    )}
                            </>
                        )}
                    </p> */}
                    <p
                        className="each_holder"
                        key={`onbstatus_each_holder_2_${index}`}>
                        {item.appointment_type ===
                            'Telehealth Visit' && (
                            <>
                                <img
                                    src={
                                        TeleHealthIcon
                                    }></img>
                                <span className="name">
                                    Telehealth
                                </span>
                            </>
                        )}
                        {item.appointment_type ===
                            'Office Visit' && (
                            <>
                                <img
                                    src={
                                        BuildingIcon
                                    }></img>
                                <span className="name">
                                    In Office
                                </span>
                            </>
                        )}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div
        className="icon_holder"
        key={`div_icon_holder_1_${index}`}>
        {item.CARD_STATUS ===
            ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED && (
            <LightTooltip
                title={
                    !telehealthScreen.active
                        ? 'Start encounter'
                        : 'Please leave the current session to join'
                }
                key={`enc_tooltip_1_${index}`}>
                <div
                    className="each_icon_holder play"
                    key={`enc_icon_holder_1_${index}`}>
                    <ButtonSpinnerEncounter
                        label={
                            <>
                                <img src={PlayIcon}></img>
                            </>
                        }
                        className="each_icon_holder play"
                        onclick={() => {
                            if (
                                Object.keys(
                                    saveAndCloseDialog,
                                )[0] !== '0'
                            ) {
                                dispatch(
                                    handleOpenSaveAndCloseDialog(
                                        {
                                            [Object.keys(
                                                saveAndCloseDialog,
                                            )[0]]: true,
                                        },
                                    ),
                                )
                                dispatch(
                                    handleSetCurrentEncounter(
                                        item,
                                    ),
                                )
                            } else {
                                handleStartEncounter(item)
                            }
                            setTimeout(
                                () =>
                                    refetchEncounters(
                                        {
                                            forceUpdate: true,
                                        },
                                    ),
                                1000,
                            )
                        }}
                        disabled={
                            !shedulePermission.includes(
                                'start_encounter',
                            ) || telehealthScreen.active
                        }
                        isLoading={
                            loading &&
                            selectedEncounter &&
                            selectedEncounter.encounter_id ===
                                item.encounter_id
                        }
                        key={`enc_spinner_1_${index}`}
                    />
                </div>
            </LightTooltip>
        )}
        {item.CARD_STATUS ===
            ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION && (
            <>
                {selectedEncounter === null &&
                    item.appointment_type ===
                        'Telehealth Visit' && (
                        <LightTooltip
                            title="Join encounter"
                            key={`enc_tooltip_2_${index}`}>
                            <div
                                className="each_icon_holder join"
                                key={`enc_icon_holder_2_${index}`}>
                                <Button
                                    className="each_icon_holder join"
                                    onClick={() => {
                                        handleJoinEncounter(item,)
                                        setTimeout(
                                            () =>
                                                refetchEncounters(
                                                    {
                                                        forceUpdate: true,
                                                    },
                                                ),
                                            1000,
                                        )
                                    }}
                                    key={`enc_joiner_1_${index}`}
                                    disabled={
                                        item.appointment_type ===
                                            'Office Visit' ||
                                        !shedulePermission.includes(
                                            'join_encounter',
                                        ) ||
                                        telehealthScreen.active
                                    }>
                                    <img
                                        src={JoinEncIcon}
                                    />
                                </Button>
                            </div>
                        </LightTooltip>
                    )}

                {selectedEncounter &&
                    selectedEncounter.encounter_id ===
                        item.encounter_id &&
                    item?.appointment_type ===
                        'Telehealth Visit' && (
                        <>
                            <LightTooltip
                                title="Leave encounter"
                                key={`enc_tooltip_3_${index}`}>
                                <div
                                    className="each_icon_holder leave"
                                    key={`enc_icon_holder_3_${index}`}>
                                    <Button
                                        className="each_icon_holder leave"
                                        onClick={() => {
                                            handleLeaveEncounter(
                                                item,
                                            )
                                            setTimeout(
                                                () =>
                                                    refetchEncounters(
                                                        {
                                                            forceUpdate: true,
                                                        },
                                                    ),
                                                1000,
                                            )
                                        }}
                                        key={`enc_btn_leave_1_${index}`}
                                        disabled={
                                            (item.appointment_type ===
                                                'Office Visit' &&
                                                isQuitBtn(
                                                    item,
                                                )) ||
                                            !shedulePermission.includes(
                                                'leave_encounter',
                                            )
                                        }>
                                        <img
                                            src={
                                                LeaveEncIcon
                                            }
                                        />
                                    </Button>
                                </div>
                            </LightTooltip>
                        </>
                    )}

                {/* {selectedEncounter &&
                        selectedEncounter.encounter_id ===
                        item.encounter_id && ( */}
                {((item?.appointment_status ===
                    'checked-in' &&
                    item.appointment_type ===
                        'Telehealth Visit') ||
                    (item?.status === 'EXAM' &&
                        item.appointment_type ===
                            'Office Visit')) && (
                    <>
                        <LightTooltip
                            title="End encounter"
                            key={`enc_tooltip_4_${index}`}>
                            <div
                                className="each_icon_holder end"
                                key={`enc_icon_holder_4_${index}`}>
                                <Button
                                    className="each_icon_holder end"
                                    key={`enc_btn_end_1_${index}`}
                                    disabled={
                                        (selectedEncounter &&
                                            selectedEncounter.encounter_id ===
                                                item.encounter_id &&
                                            isEndBtn(
                                                item,
                                            )) ||
                                        !shedulePermission.includes(
                                            'end_encounter',
                                        )
                                    }
                                    onClick={(e) =>
                                        handleEndClick(
                                            e,
                                            item,
                                        )
                                    }>
                                    <img
                                        src={
                                            EndEncIcon
                                        }></img>
                                </Button>
                            </div>
                        </LightTooltip>
                    </>
                )}
            </>
        )}
    </div>

    {(!item?.clinical_note_id ||
        item?.clinical_note_id === '') && (
        <div style={{ position: 'relative' }}>
            <span
                style={{
                    position: 'absolute',
                    top: 30,
                    left: '-16px',
                }}>
                <NoClinicalNoteIcon
                    style={{ height: 15, width: 15 }}
                />
            </span>
        </div>
    )}
</ListItem>
}

export default memo(EncounterItem)