import React, { useMemo, useState, useEffect } from 'react'
import moment from 'moment'

//mui-components
import {
    Grid,
    Typography,
    Box,
    Divider,
    IconButton,
    Tooltip,
} from '@mui/material'
import { Popover } from '@mui/material'

//svg-icons
import { ReactComponent as ProviderIcon } from '../icons/provider.svg'
import { ReactComponent as PatientIcon } from '../icons/patient.svg'
import { ReactComponent as AppointmentTypeIcon } from '../icons/telehealth.svg'
import { ReactComponent as TimeIcon } from '../icons/clock.svg'
import { ReactComponent as EditIcon } from '../icons/edit.svg'
import { ReactComponent as ResendIcon } from '../icons/location.svg'
import { ReactComponent as LinkIcon } from '../icons/link.svg'
import { ReactComponent as FileIcon } from '../icons/file.svg'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as ReportIcon1 } from '../../../../assests/images/report_icon_1.svg'

//utility functions and components
import {
    getCustomizedDateFormat,
    shortenString,
} from 'containers/CalendarScheduler/utils'
import { appointmentStatus as generateAppointmentStatus } from './appointmentStatus'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import {
    deleteAppointment,
    deleteAppointmentSeries,
    getEventCardInfo,
    postStatusChangeRequest,
    sendResetConfirmationLink,
} from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import DeleteIcon from 'assests/icons/DeleteIcon'
import { statusNames } from 'containers/CalendarScheduler/constant'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { convertToBase64 } from 'utilities/stringOperations'
import { getEncounterQueueListAPI } from 'services/Provider/provider'
import { CustomStatusChangeDialogBody, ConfirmReasonDialogBody } from 'components/shared/Elements/CustomStatusChangeDialogBody'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import openLog from 'assests/icons/open.svg'
import closeLog from 'assests/icons/close.svg'
import AppointmentLog from './AppointmentLog'

import { makeStyles } from '@material-ui/core'
import TrashIcon from 'assests/icons/TrashIcon'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import AlertPopOverV2 from 'components/shared/Elements/AlertPopOverV2'
import { useTimezone } from 'shared/hooks/useTimezone'
import { APPOINTMENT_STATUSES } from 'containers/CalendarScheduler/types'

const useStyles = makeStyles(() => ({
    buttonDisabled: { pointerEvents: 'none' },
    buttonEnabled: { pointerEvents: 'auto' },
    txtCss: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 140,
    },
}))

export function EventContentBusySlotDay(props) {
    return (
        <div className="tooltip-wrapper">
            <Grid container>
                <Typography>{moment(new Date(props.start).getTime()).format('hh:mm A')}</Typography>
                <Typography
                    style={{
                        margin: '0px 4px',
                    }}>{` - `}</Typography>
                <Typography>{moment(new Date(props.end).getTime()).format('hh:mm A')}</Typography>
                <Typography noWrap className="black capitalize" style={{ margin: '0px 16px' }}>
                    {props.patientName}
                </Typography>
                <Typography noWrap className="black">
                    {props.appointmentType}
                </Typography>
                <Typography
                    style={{
                        margin: '0px 4px',
                    }}>{` - `}</Typography>
                <Typography styles={{ paddingLeft: '10px' }} noWrap className="black">
                    <span>
                        {props.practitioner_first_name || ''} {props.practitioner_last_name || ''}
                    </span>
                </Typography>
            </Grid>
        </div>
    )
}

export function EventContentBusySlotWeek(props) {
    return (
        <div className="tooltip-wrapper">
            <Grid container>
                <Typography>{moment(new Date(props.start).getTime()).format('hh:mm A')}</Typography>
                <Typography
                    style={{
                        margin: '0px 4px',
                    }}>{` - `}</Typography>
                <Typography>{moment(new Date(props.end).getTime()).format('hh:mm A')}</Typography>
                <Typography noWrap className="black capitalize" style={{ margin: '0px 16px' }}>
                    {props.patientName}
                </Typography>
                <Typography noWrap className="black">
                    {props.appointmentType}
                </Typography>
            </Grid>
        </div>
    )
}

export function EventContentTimeOffMonth(props) {
    return (
        <div className="tooltip-wrapper">
            <div style={{ marginBottom: '10px' }}>
                <Typography variant="subtitle1">
                    <strong>{moment(props.start).format('ddd DD MMM, YYYY')} </strong>
                </Typography>
                <Typography noWrap variant="caption">
                    {moment(props.start).format('hh:mm A')} - {moment(props.end).format('hh:mm A')}
                </Typography>
            </div>
        </div>
    )
}

export function EventContentGeneral(props) {
    const newStyles = useStyles()
    const [isWaitlist, setIsWaitlist] = useState(false)
    const [isRecallList, setIsRecallList] = useState(false)
    const [isCreateNote, setIsCreateNote] = useState(true)
    const [isChartRecord, setIsChartRecord] = useState(true)
    const [eventCardInfo, setEventCardInfo] = useState({})

    const handleCheckWaitlist = () => setIsWaitlist(!isWaitlist)
    const handleCheckRecallList = () => setIsRecallList(true);
    const handleCheckCreateNote = () => setIsCreateNote(!isCreateNote)
    const handleCheckChartRecord = () => setIsChartRecord(!isChartRecord)
    const [loaderInfo, setLoaderInfo] = useState(false)
    const getEqDate = useSelector((state) => state.provider.encounterQueueListDate)

    const [currentReason, setCurrentReason] = useState('')

    const { locationTimezone } = useTimezone()
    const formattedStatusNames = useMemo(() => {
        return(
            [...statusNames?.filter((val) => val !== props?.status)]
        )
    }, [props?.status])

    const getEventCardExtraInfo = () => {
        if (props?.patient_id) {
            setLoaderInfo(true)
            getEventCardInfo(props?.encounter_id, props?.patient_id)
                ?.then((res) => {
                    setEventCardInfo(res.data)
                    setLoaderInfo(false)
                })
                .catch((err) => {
                    notification('Event Card Info Not Found!', 'error')
                    setLoaderInfo(false)
                })
        }
    }

    useEffect(() => {
        getEventCardExtraInfo()
    }, [])

    const formatTimezoneName = (data) => {
        if (!data || data === '') return
        return data.replace('US/', '')
    }

    const statusObject = useMemo(() => generateAppointmentStatus(props?.status), [props?.status])
    const notification = useNotification()

    const userData = useSelector((state) => state?.login?.userData)

    const classes = props.classes

    //resend confirmation popover
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const onSuccess = async () => {
        try {
            handleClose()
            const res = await sendResetConfirmationLink(props?.id)
            if (res?.status === 200) {
                notification(`Confirmation Link resent successfully!`, 'success')
            }
        } catch {
            handleClose()
            notification(`Confirmation Link resent unsuccessfully!`, 'error')
        }
    }

    const deleteOptions = [
        {
            label: 'Delete Occurrence',
            method: function deleteSingle() {
                setopenTooltipOption(false)
                setOpenTooltip(true)
            },
        },
        {
            label: 'Delete Series',
            method: async function deleteseries() {
                setopenTooltipOption(false)
                setopenTooltipOptionseries(true)
            },
        },
    ]

    const onEditButtonInvokedstaffSeries = (eventId) => {
        props?.handleClose()
        props.onSelectEventStaff({ id: eventId, series_edit: true })
    }
    const EditOptions = [
        {
            label: 'Edit Occurrence',
            method: function EditSingle(eventid) {
                onEditButtonInvokedstaff(eventid)
            },
        },
        {
            label: 'Edit Series',
            method: function Editseries(eventid) {
                onEditButtonInvokedstaffSeries(eventid)
            },
        },
    ]

    const noPostActionStatus = [APPOINTMENT_STATUSES.fulfilled]

    //status popover
    const [anchorEl2, setAnchorEl2] = useState(null)
    const [anchorElNoShow, setAnchorElNoShow] = useState(null)
    const [anchorElCancel, setAnchorElCancel] = useState(null)
    const [currentAppointmentStatus, setCurrentAppointmentStatus] = useState('')
    const [anchorReasonCancelOrNoshow, setAnchorReasonCancelOrNoshow] = useState(null)

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget)
    }

    const handleClose2 = () => {
        setAnchorEl2(null)
    }

    const handleCloseNoShow = () => {
        setAnchorElNoShow(null)
        setCurrentAppointmentStatus('')
    }

    const handleOpenCancelOrConfirm = (event, appointmentStatus) => {
        setAnchorElCancel(event.currentTarget)
        setCurrentAppointmentStatus(appointmentStatus)
    }

    const handleCloseCancelOrConfirm = () => {
        setAnchorElCancel(null)
        setCurrentAppointmentStatus('')
        setIsWaitlist(false)
        setIsRecallList(false)
        setIsCreateNote(true)
        setIsChartRecord(true)
    }

    const onStatusChange = async (currentStatus: APPOINTMENT_STATUSES, dependables = null, currentCancellationReason: string | null = null): Promise<void> => {
        props.optimisticUpdate(props?.id, {
            status: currentStatus
        })
        handleClose2()
        handleCloseCancelOrConfirm()
        props.handleClose()
        localStorage.setItem('updatingAppointmentId', JSON.stringify(props?.id))

        try {
            const res = await postStatusChangeRequest(currentStatus, props?.id, dependables, locationTimezone, currentCancellationReason)

            if (!res || !res.data) {
                throw new Error('No response from server');
            }

            notification('Appointment Status Updated Successfully!', 'success')
    
            setAnchorElNoShow(null)
            setAnchorElCancel(null)
            setCurrentAppointmentStatus('')
        } catch (error) {
            notification('Something Went Wrong!', 'error')

            const data = error?.data

            if (data) {
                return console.error('FAILED: ', JSON.stringify(data, null, 1))
            }

            console.error(error)
        } finally {
            props.refetchSchedule(getEqDate)
            props.getApiEncounterQueue()
            localStorage.removeItem('updatingAppointmentId')
        }
    }

    const onSuccess2 = (currentStatus: APPOINTMENT_STATUSES) => {
        const is_delete =
                currentStatus === APPOINTMENT_STATUSES.noshow || currentStatus === APPOINTMENT_STATUSES.cancelled ? true : false,
            add_clinical_note =
                currentStatus === APPOINTMENT_STATUSES.booked && !eventCardInfo?.clinical_note_id
                    ? isCreateNote
                    : false,
            add_chart_record =
                currentStatus === APPOINTMENT_STATUSES.booked && !props?.chart_status ? isChartRecord : false,
            add_recall = isRecallList,
            add_waitlist = isWaitlist

        const dependables = {
            is_delete_note: is_delete,
            delete_chart_record: is_delete,
            add_recall,
            add_waitlist,
            add_chart_record,
            add_clinical_note,
        }

        const currentCancellationReason = 
            currentStatus === 'cancelled' || currentStatus === 'noshow' ? currentReason : null

        onStatusChange(currentStatus, dependables, currentCancellationReason)
    }

    const OnChangingStatus = () => {
        if (currentAppointmentStatus !== '') onSuccess2(currentAppointmentStatus)
    }

    const open2 = Boolean(anchorEl2)
    const endId2 = open2 ? 'simple-popover' : undefined

    const openNoShow = Boolean(anchorElNoShow)
    const endIdNoShow = openNoShow ? 'simple-popover' : undefined

    //resend confirmation popover
    const [anchorEl3, setAnchorEl3] = useState(null)
    const [appointmentLogOpen, setAppointmentLogOpen] = useState(false)
    const [anchorEl4, setAnchorEl4] = useState(null)
    const [anchorEl6, setAnchorEl6] = useState(null)
    const [anchorEl7, setAnchorEl7] = useState(null)

    const [openTooltipOption, setopenTooltipOption] = useState(false)
    const [openTooltipOptionEdit, setopenTooltipOptionEdit] = useState(false)

    const [openTooltipOptionseries, setopenTooltipOptionseries] = useState(false)

    const [openTooltip, setOpenTooltip] = useState(false)

    const reoccurringAppointment = props?.is_reoccurring_appointment

    const isReoccurringAppointment = reoccurringAppointment && reoccurringAppointment !== 'undefined' && JSON.parse(reoccurringAppointment || '{}')

    const handleToolTipclose = () => {
        setOpenTooltip(false)
    }

    const handleToolTipseriesclose = () => {
        setopenTooltipOptionseries(false)
    }

    const handleToolTipclose1 = () => {
        setopenTooltipOption(false)
    }

    const handleToolTipclose2 = () => {
        setopenTooltipOptionEdit(false)
    }

    const handleStaffdeleteoptions = (e) => {
        setAnchorEl6(e.currentTarget)
        setopenTooltipOption(!openTooltipOption)
    }

    const handleToolTipOpen = () => {
        setOpenTooltip(true)
    }
    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget)
    }

    const handleClose3 = () => {
        setAnchorEl3(null)
    }

    const handleClose4 = () => {
        setAnchorEl4(null)
        setAnchorEl3(null)
        handleToolTipclose()
    }

    const handleClose5 = () => {
        setAnchorEl7(null)
        handleToolTipseriesclose()
    }

    const onSuccess3 = async (): Promise<void> => {
        handleClose3()
        props.handleClose()
        props.optimisticDelete(props?.id)
        
        try {
            const res = await deleteAppointment(props?.id)

            if (!res || !res.data) {
                throw new Error('No response from server')
            }

            if (!res.data['message']) {
                throw new Error (`FAILED: ${JSON.stringify(res.data, null, 1)}`)
            }

            notification(`Appointment Deleted Successfully!`, 'success')
        } catch (error) {
            console.log(error)
            notification('Something Went Wrong!', 'error')
        } finally {
            props.refetchSchedule(getEqDate)
            props.getApiEncounterQueue()
        }
    }

    const onSuccess4 = async () => {
        setOpenTooltip(false)
        setopenTooltipOption(false)
        deleteAppointment(props?.id).then((res) => {
            props?.getApiEncounterQueue()
            props?.refetchSchedule(getEqDate)
            props.handleClose()
            handleClose4()
            notification(`Appointment Deleted Successfully!`, 'success')
        })
    }

    const onSuccess5 = async () => {
        setOpenTooltip(false)
        setopenTooltipOption(false)
        deleteAppointmentSeries(props?.id).then((res) => {
            props?.getApiEncounterQueue()
            props?.refetchSchedule(getEqDate)
            props.handleClose()
            handleClose5()
            notification(`Appointment Series Deleted Successfully!`, 'success')
        })
    }

    const onEditButtonInvoked = (eventId) => {
        props?.handleClose()
        props.onSelectEvent({ id: eventId })
    }

    const onEditButtonInvokedstaff = (eventId) => {
        props?.handleClose()
        props.onSelectEventStaff({ id: eventId, series_edit: false })
    }

    const onEditButtonInvokedstaffOption = () => {
        setopenTooltipOptionEdit(!openTooltipOptionEdit)
    }

    const confirmationLink = (link) => {
        if (!link || link === '') return null
        return link
    }
    const handleClickAppointLog = () => {
        setAppointmentLogOpen(!appointmentLogOpen)
    }

    const renderConfirmationLink = (): JSX.Element => {
        if (props.status === APPOINTMENT_STATUSES.pending || props.appointmentType === 'Office Visit') {
            return <Typography>No Confirmation Link</Typography>
        }

        if (props.status === APPOINTMENT_STATUSES.booked && !confirmationLink(eventCardInfo?.generate_link)) {
            return (
                <Tooltip
                    title='Usually takes one or two minutes'
                    arrow
                    placement='bottom'
                >
                    <Typography>Encounter link is being generated</Typography>
                </Tooltip>
            )
        }

        return (
                <Tooltip
                    title={confirmationLink(eventCardInfo?.generate_link)}
                    arrow
                    placement="bottom">
                    <Typography
                        onClick={handleClick}
                        style={{ 
                            color: '#5571C6', 
                            cursor: props?.status === APPOINTMENT_STATUSES.fulfilled ? 'default' : 'pointer',
                            pointerEvents: props?.status === APPOINTMENT_STATUSES.fulfilled ? 'none' : 'auto'
                        }}
                        className={`${classes.font_weight_400} ${classes.font_16}`}>
                        TeleMed2U Link
                    </Typography>
                </Tooltip>
            )
    }

    return (
        <div className="tooltip-wrapper" style={{ minWidth: '300px' }}>
            {/*Resend Confirmation Link*/}
            {appointmentLogOpen && (
                <AppointmentLog
                    open={appointmentLogOpen}
                    close={handleClickAppointLog}
                    id={props?.id}
                />
            )}
            <CustomAlertPopOver
                anchorEl={anchorEl}
                handleClose={handleClose}
                title={'Resend Appointment URL'}
                bodyText={`Are you sure you want to send <b>${props?.patientName}</b> an encounter link?`}
                confirmButtonName={'send'}
                onSuccess={onSuccess}
            />

            {/*Delete Appointment*/}
            <CustomAlertPopOver
                anchorEl={anchorEl3}
                handleClose={handleClose3}
                title={'Appointment Deletion'}
                bodyText={`Are you sure you want to delete this appointment? (The associated clinical note will be removed.)`}
                confirmButtonName={'Delete'}
                confirmButtonColor={'#D22121'}
                onSuccess={onSuccess3}
            />

            {/*Cancel Confirmation or Appointment Confirmation*/}
            <CustomAlertPopOver
                id='status_confirmation_popover'
                anchorEl={anchorElCancel}
                handleClose={handleCloseCancelOrConfirm}
                title={
                    currentAppointmentStatus === APPOINTMENT_STATUSES.cancelled
                        ? 'Cancellation Confirmation'
                        : currentAppointmentStatus === APPOINTMENT_STATUSES.noshow
                        ? 'No Show Confirmation'
                        : 'Appointment is confirmed!'
                }
                confirmButtonName={'OK'}
                cancelButtonName={'Cancel'}
                confirmButtonColor={
                    currentAppointmentStatus === APPOINTMENT_STATUSES.cancelled ||
                    currentAppointmentStatus === APPOINTMENT_STATUSES.noshow
                        ? 'red'
                        : 'blue'
                }
                iconColor={
                    currentAppointmentStatus === APPOINTMENT_STATUSES.cancelled ||
                    currentAppointmentStatus === APPOINTMENT_STATUSES.noshow
                        ? 'red'
                        : 'green'
                }
                onSuccess={(event) => {
                    if(currentAppointmentStatus === 'booked') OnChangingStatus()
                    else setAnchorReasonCancelOrNoshow(anchorElCancel)
                }}
                loading={props.loadStatus}>
                <CustomStatusChangeDialogBody
                    isWaitlist={isWaitlist}
                    handleCheckWaitlist={handleCheckWaitlist}
                    isRecallList={isRecallList}
                    handleCheckRecallList={handleCheckRecallList}
                    isCreateNote={isCreateNote}
                    handleCheckCreateNote={handleCheckCreateNote}
                    isChartRecord={isChartRecord}
                    handleCheckChartRecord={handleCheckChartRecord}
                    statusObject={generateAppointmentStatus(currentAppointmentStatus)}
                    clinical_notes_id={eventCardInfo?.clinical_note_id}
                    chart_status={props?.chart_status}
                />
            </CustomAlertPopOver>

            {/*Cancel and Noshow reason*/}
            <CustomAlertPopOver
                anchorEl={anchorReasonCancelOrNoshow}
                handleClose={() => {
                    handleCloseCancelOrConfirm()
                    setAnchorReasonCancelOrNoshow(null)
                    setCurrentReason('')
                }}
                title={'Select Reason'}
                confirmButtonName={'OK'}
                cancelButtonName={'Cancel'}
                confirmButtonColor={'#5571C6'}
                iconColor={'#D22121'}
                onSuccess={OnChangingStatus}
                confirmButtonDisabled={currentReason === ""}
                loading={props.loadStatus}>
                <ConfirmReasonDialogBody 
                    currentReason={currentReason}
                    setCurrentReason={setCurrentReason}
                    status={currentAppointmentStatus}
                />
            </CustomAlertPopOver>

            {/*Appointment Status Change*/}
            <Popover
                id={endId2}
                open={noPostActionStatus.includes(props?.status) ? false : open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                PaperProps={{
                    style: {
                        boxShadow: '0px 0px 10px #888888',
                    },
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        padding: '10px',
                        justifyContent: 'center',
                    }}>
                    {formattedStatusNames?.length > 0 &&
                        formattedStatusNames?.map((item, i) => {
                            const { bgColor, displayName, nativeName, icon } =
                                generateAppointmentStatus(item)
                            if (
                                eventCardInfo?.clinical_note_id &&
                                props?.status !== APPOINTMENT_STATUSES.enteredInError
                            ) {
                                return (
                                    <span key={i}>
                                        <span
                                            className={`${classes.status_button} ${classes.item_header_text}`}
                                            style={{
                                                backgroundColor: bgColor,
                                                width: '100px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: 20,
                                            }}
                                            onClick={(event) => {
                                                if (
                                                    [APPOINTMENT_STATUSES.noshow, APPOINTMENT_STATUSES.cancelled, APPOINTMENT_STATUSES.booked].includes(nativeName)
                                                ) {
                                                    handleOpenCancelOrConfirm(event, nativeName)
                                                } else onSuccess2(nativeName)
                                            }}>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                }}>
                                                {icon}
                                            </span>

                                            <span>{displayName}</span>
                                        </span>

                                        <Divider style={{ marginTop: '10px' }} />
                                    </span>
                                )
                            } else {
                                return (
                                    <span key={i}>
                                        <span
                                            className={`${classes.status_button} ${classes.item_header_text}`}
                                            style={{
                                                backgroundColor: bgColor,
                                                width: '100px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: 20,
                                            }}
                                            onClick={(event) => {
                                                if (
                                                    [APPOINTMENT_STATUSES.noshow, APPOINTMENT_STATUSES.cancelled, APPOINTMENT_STATUSES.booked].includes(nativeName)
                                                ) {
                                                    handleOpenCancelOrConfirm(event, nativeName)
                                                } else onSuccess2(nativeName)
                                            }}>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                }}>
                                                {icon}
                                            </span>

                                            <span>{displayName}</span>
                                        </span>

                                        <Divider style={{ marginTop: '10px' }} />
                                    </span>
                                )
                            }
                        })}
                </div>
            </Popover>
            {props?.admin_code_id ? (
                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        backgroundColor: 'transparent',
                        position: 'relative',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        padding: '5px 5px 5px 0',
                    }}>
                    <Divider className="w-full" />
                    <Box
                        sx={{
                            position: 'absolute',
                            mt: '10px',
                            backgroundColor: 'white',
                            content: '""',
                            display: 'block',
                            width: 15,
                            height: 15,
                            transform: 'rotate(45deg)',
                            left: 10,
                            boxShadow: '-4px 4px 6px -3px #888888',
                        }}
                    />

                    <Box
                        sx={{
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 6px #888888',
                            ml: 2,
                            borderRadius: 1,
                        }}>
                        <Box
                            style={{
                                borderBottom: '1px solid #D9D9D9',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '5px',
                                cursor: 'pointer',
                                marginRight: '17px',
                                marginLeft: '15px',
                            }}>
                            <HtmlTooltip
                                open={openTooltipOption}
                                onClose={handleToolTipclose1}
                                arrow
                                placement="bottom-start"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <div>
                                        <Box
                                            className={classes.txtCss}
                                            style={{ marginTop: '10px', cursor: 'pointer' }}
                                            onClick={() => {
                                                deleteOptions[0].method(props.id)
                                            }}>
                                            {deleteOptions[0].label}
                                        </Box>
                                        {isReoccurringAppointment && (
                                            <Box
                                                className={classes.txtCss}
                                                style={{ marginTop: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    deleteOptions[1].method(props.id)
                                                }}>
                                                {deleteOptions[1].label}
                                            </Box>
                                        )}
                                    </div>
                                }></HtmlTooltip>

                            <HtmlTooltip
                                open={openTooltip}
                                onClose={handleToolTipclose}
                                arrow
                                placement="bottom-start"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <AlertPopOverV2
                                        anchorEl={anchorEl4}
                                        handleClose={handleClose4}
                                        title={'Staff Appointment Deletion'}
                                        icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                                        bodyText={`Are you sure you want to delete this staff appointment?`}
                                        confirmButtonName={'Delete'}
                                        confirmButtonColor={'#D22121'}
                                        onSuccess={onSuccess4}
                                        customPaperStyle={{
                                            backgroundColor: 'transparent !importent',
                                        }}
                                    />
                                }></HtmlTooltip>
                            <div onClick={(e) => handleStaffdeleteoptions(e)}>
                                <TrashIcon />
                            </div>
                            <HtmlTooltip
                                open={openTooltipOptionseries}
                                onClose={handleToolTipseriesclose}
                                arrow
                                placement="bottom-start"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                    <AlertPopOverV2
                                        anchorEl={anchorEl7}
                                        handleClose={handleClose5}
                                        title={'Delete Series?'}
                                        icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                                        bodyText={`Are you sure you want to delete all staff appointments in this series?`}
                                        confirmButtonName={'Delete'}
                                        confirmButtonColor={'#D22121'}
                                        onSuccess={onSuccess5}
                                        customPaperStyle={{
                                            backgroundColor: 'transparent !importent',
                                        }}
                                    />
                                }></HtmlTooltip>
                        </Box>
                        <div className={`${classes.card_item_padding}`}>
                            <Typography
                                style={{
                                    color: '#303E4E',
                                    cursor: 'pointer',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    marginBottom: '5px',
                                }}
                                variant="h6"
                                className={classes.text_bold}>
                                {props?.code_title}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <Typography
                                    style={{
                                        color: '#303E4E',
                                        cursor: 'pointer',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                    }}>
                                    {props?.admin_code}
                                </Typography>
                                <HtmlTooltip
                                    open={openTooltipOptionEdit}
                                    onClose={handleToolTipclose2}
                                    arrow
                                    placement="bottom-start"
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                        <div>
                                            <Box
                                                className={classes.txtCss}
                                                style={{ marginTop: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    EditOptions[0].method(props.id)
                                                }}>
                                                {EditOptions[0].label}
                                            </Box>
                                            {isReoccurringAppointment && (
                                                <Box
                                                    className={classes.txtCss}
                                                    style={{ marginTop: '10px', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        EditOptions[1].method(props.id)
                                                    }}>
                                                    {EditOptions[1].label}
                                                </Box>
                                            )}
                                        </div>
                                    }>
                                    <Tooltip arrow title="Edit Appointment" placement="top">
                                        <IconButton
                                            className={classes.card_icon_button}
                                            onClick={() => onEditButtonInvokedstaffOption()}
                                            disabled={props?.status === 'checked-in'}>
                                            <EditIcon
                                                className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </HtmlTooltip>
                            </div>
                        </div>

                        <div className={classes.card_item_padding}>
                            <div
                                className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                <InfoIcon
                                    className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                />
                                <Typography
                                    style={{
                                        color: '#303E4E',
                                        cursor: 'pointer',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                    }}>
                                    {props?.code_description}
                                </Typography>
                            </div>
                        </div>
                        <Divider className="w-full" />
                        <div className={classes.card_item_padding}>
                            <div
                                className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                <TimeIcon
                                    className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                />
                                <Typography
                                    variant="h6"
                                    className={`${classes.text_bold} ${classes.default_text_color} ${classes.font_weight_500} ${classes.font_16}`}>
                                    {moment(new Date(props.start).getTime()).format('hh:mm A')}
                                    {`  -  `}
                                    {moment(new Date(props.end).getTime()).format('hh:mm A')}
                                </Typography>
                            </div>
                        </div>
                    </Box>
                </span>
            ) : (
                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        backgroundColor: 'transparent',
                        position: 'relative',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        padding: '5px 5px 5px 0',
                    }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            mt: '10px',
                            backgroundColor: 'white',
                            content: '""',
                            display: 'block',
                            width: 15,
                            height: 15,
                            transform: 'rotate(45deg)',
                            left: 10,
                            boxShadow: '-4px 4px 6px -3px #888888',
                        }}
                    />

                    <Box
                        sx={{
                            backgroundColor: 'white',
                            boxShadow: '0px 0px 6px #888888',
                            ml: 2,
                            borderRadius: 1,
                        }}>
                        <div
                            className={`${classes.card_item_padding} ${classes.default_flex_view} ${classes.flex_justify_between}`}>
                            <span
                                className={`${classes.status_button} ${classes.item_header_text} ${
                                    loaderInfo || props.loadStatus
                                        ? newStyles.buttonDisabled
                                        : newStyles.buttonEnabled
                                }`}
                                style={{
                                    backgroundColor: statusObject?.bgColor,
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={handleClick2}>
                                <span className={classes.default_flex_view}>
                                    {statusObject?.icon}
                                </span>

                                <span className={`${classes.font_weight_400} ${classes.font_14}`}>
                                    {statusObject?.displayName}{' '}
                                    {props.loadStatus && (
                                        <CircularProgress size={14} color="black" />
                                    )}
                                </span>
                            </span>
                            <span>
                                <img
                                    src={appointmentLogOpen ? closeLog : openLog}
                                    alt=""
                                    style={{
                                        height: '15px',
                                        paddingRight: '7px',
                                        marginBottom: '-3px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={handleClickAppointLog}
                                />
                                {(userData?.role_code === 'tenant_admin' ||
                                    userData?.role_code === 'super_user') && (
                                    <Tooltip title="Delete Appointment" arrow placement="top">
                                        <IconButton className={classes.card_icon_button} onClick={handleClick3}>
                                            <DeleteIcon
                                                className={`${classes.card_action_icon} ${classes.card_icon_large_font}`}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </span>
                        </div>
                        <Divider className="w-full" />

                        <div className={classes.card_item_padding}>
                            <Tooltip title="View Patient" arrow placement="top">
                                <div
                                    className={`${classes.default_flex_view} ${classes.flex_justify_start}`}
                                >
                                    <PatientIcon
                                        className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                    />
                                    <Link
                                        to={`/patient/${props?.patient_id}/patient-record`}
                                        target="_blank">
                                        <Typography
                                            style={{
                                                color: '#5571C6',
                                                cursor: 'pointer',
                                                fontWeight: 500,
                                                fontSize: '16px',
                                            }}
                                            variant="h6"
                                            className={classes.text_bold}>
                                            {shortenString(props?.patientName, 12) ||
                                                'No Patient Name'}
                                        </Typography>
                                    </Link>
                                    <Typography
                                        style={{ marginLeft: '5px' }}>{`(${formatTimezoneName(
                                        props?.patientTimezone || 'N/A',
                                    )})`}</Typography>
                                </div>
                            </Tooltip>

                            <div style={{ marginLeft: '22px' }}>
                                <div
                                    className={`${classes.default_margin_top} ${classes.default_flex_view} ${classes.flex_justify_start} ${classes.full_width}`}>
                                    <span
                                        style={{
                                            marginRight: '25px',
                                            width: '150px',
                                            color: '#303E4E',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                        }}>
                                        {props?.patientPhone ?? 'N/A'}
                                    </span>

                                    <span
                                        className={`${classes.default_text_color} ${classes.font_weight_400} ${classes.font_14}`}>
                                        {getCustomizedDateFormat(props?.patientDob) ?? 'N/A'}
                                    </span>
                                </div>

                                <div
                                    className={`${classes.default_margin_top} ${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                    <span
                                        style={{ marginRight: '30px', color: '#667689' , width: '150px' }}
                                        className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                        <span style={{ display: 'block', paddingRight: '5px' }}>
                                            Co Pay:
                                        </span>
                                        {loaderInfo
                                            ? 'Loading...'
                                            : eventCardInfo?.insurance?.insurance?.copay_amount}
                                    </span>
                                </div>
                                <div
                                    className={`${classes.default_margin_top} ${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                    <span
                                        style={{ marginRight: '30px', color: '#667689' }}
                                        className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                        <span style={{ display: 'block', paddingRight: '5px' }}>
                                            Clincial Practice:
                                        </span>
                                        {loaderInfo
                                            ? 'Loading...'
                                            : eventCardInfo?.clinical_practice_name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Divider className="w-full" />

                        <div className={classes.card_item_padding}>
                            <div
                                className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                <TimeIcon
                                    className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                />
                                <Typography
                                    variant="h6"
                                    className={`${classes.text_bold} ${classes.default_text_color} ${classes.font_weight_500} ${classes.font_16}`}>
                                    {moment(new Date(props.start).getTime()).format('hh:mm A')}
                                    {`  -  `}
                                    {moment(new Date(props.end).getTime()).format('hh:mm A')}
                                </Typography>
                            </div>

                            <div
                                className={`${classes.default_flex_view} ${classes.flex_justify_between} ${classes.default_margin_top}`}>
                                <span
                                    className={`${classes.default_flex_view} ${classes.flex_justify_between}`}>
                                    <ProviderIcon
                                        className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                    />
                                    <Typography
                                        className={`${classes.default_text_color} ${classes.font_weight_400} ${classes.font_16}`}>
                                        {props.practitioner_first_name || ''}{' '}
                                        {shortenString(props.practitioner_last_name, 15) || ''}
                                    </Typography>
                                    <Typography
                                        style={{ marginLeft: '5px' }}
                                        className={`${classes.default_text_color} ${classes.font_weight_400} ${classes.font_16}`}>{`(${formatTimezoneName(
                                        props?.practitioner_timezone || 'N/A',
                                    )})`}</Typography>
                                </span>
                                <Tooltip arrow title="Edit Appointment" placement="top">
                                    <IconButton className={classes.card_icon_button}
                                        onClick={() => onEditButtonInvoked(props?.id)}
                                        disabled={props?.status === 'checked-in'}
                                    >
                                        <EditIcon
                                            className={`${classes.card_action_icon} ${classes.card_icon_large_font}`}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            {eventCardInfo?.clinical_note_id && props?.status !== APPOINTMENT_STATUSES.noshow && (
                                <div
                                    className={`${classes.default_flex_view} ${classes.flex_justify_between}`}>
                                    <span
                                        className={`${classes.default_flex_view} ${classes.flex_justify_between}`}>
                                        <AppointmentTypeIcon
                                            className={`${classes.card_icon} ${classes.card_icon_large_font}`}
                                        />
                                        <Typography
                                            className={`${classes.default_text_color} ${classes.font_weight_400} ${classes.font_16}`}>
                                            {props.appointmentType}
                                        </Typography>
                                    </span>
                                    {(() => {
                                        const storedClinicalNotes =
                                            localStorage.getItem('visitedClinicalNotes')
                                        const visitedClinicalNotes =
                                            storedClinicalNotes && JSON.parse(storedClinicalNotes)
                                        if (
                                            visitedClinicalNotes &&
                                            eventCardInfo?.clinical_note_id &&
                                            visitedClinicalNotes?.includes(
                                                eventCardInfo?.clinical_note_id,
                                            )
                                        ) {
                                            return (
                                                <Tooltip
                                                    arrow
                                                    title="Clinical Note in Edit Mode: Already open in another tab."
                                                    placement="top">
                                                    <IconButton 
                                                        className={classes.card_icon_button}
                                                        onClick={() =>
                                                            notification(
                                                                'Clinical Note in Edit Mode: Already open in another tab.',
                                                                'warning',
                                                            )
                                                        }>
                                                        <FileIcon
                                                            className={`${classes.card_action_icon} ${classes.card_icon_large_font}`}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                        return (
                                            <Link
                                                to={`/work-queues/clinical-note/${convertToBase64(
                                                    eventCardInfo?.clinical_note_id,
                                                )}/edit`}
                                                target="_blank">
                                                <Tooltip
                                                    arrow
                                                    title="View Clinical Note"
                                                    placement="top">
                                                    <IconButton className={classes.card_icon_button}>
                                                        <FileIcon
                                                            className={`${classes.card_action_icon} ${classes.card_icon_large_font}`}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                        )
                                    })()}
                                </div>
                            )}
                        </div>
                        <Divider className="w-full" />

                        <div
                            className={`${classes.card_item_padding} ${classes.default_flex_view} ${classes.flex_justify_between}`}>
                            <span
                                className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                <LinkIcon className={classes.card_icon} />
                                {loaderInfo ? (
                                    'Loading...'
                                ) : renderConfirmationLink()}
                            </span>

                            {!confirmationLink(eventCardInfo?.generate_link) ? (
                                <IconButton className={classes.card_icon_button} disabled>
                                    <ResendIcon
                                        className={`${classes.card_action_icon} ${classes.card_icon_large_font}`}
                                    />
                                </IconButton>
                            ) : (
                                <Tooltip title="Resend Confirmation Link" arrow placement="bottom">
                                    <IconButton className={classes.card_icon_button} onClick={handleClick} disabled={props?.status === APPOINTMENT_STATUSES.fulfilled}>
                                        <ResendIcon
                                            className={`${classes.card_action_icon} ${classes.card_icon_large_font}`}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </Box>
                </span>
            )}
        </div>
    )
}