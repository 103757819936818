import { RefObject, useRef, useEffect } from 'react'

type UseObserverType = {
    ref: RefObject<HTMLDivElement>
    canLoad: boolean
    callback: () => void
}

export const useObserver = ({ ref, canLoad, callback }: UseObserverType): void => {
    const observer = useRef<IntersectionObserver>()

    useEffect(() => {
        const cb = ([entry]: IntersectionObserverEntry[]) => {
            if(entry.isIntersecting && canLoad) {
                callback()
            }
        }

        observer.current = new IntersectionObserver(cb)
        ref.current && observer.current.observe(ref.current)

        return () => {
            observer.current && observer.current.disconnect()
        }
    }, [ ref, callback, canLoad ])
}