import {
    ENCOUNTER_QUEUE_PATIENT_STATUS_LIST,
} from '../../constant'

export const renderEncounterQueuePatientStatusValue = (status?: string): string => {
    if (status === '' || !status) return ''

    return ENCOUNTER_QUEUE_PATIENT_STATUS_LIST.find((element) => element.value === status)
        ?.value || ''
}

export const formatTime = (decimalMinutes: number): string => {
    if (decimalMinutes) {
        const min = Math.abs(decimalMinutes)
        const fractionalPart = min - Math.floor(min)
        const seconds = fractionalPart < 0.99 ? Math.round(fractionalPart * 60) : 0 // Convert fraction to seconds and round
        const minutes = fractionalPart < 0.99 ? Math.floor(min) : Math.floor(min) + 1
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = seconds.toString().padStart(2, '0')

        return `${formattedMinutes}:${formattedSeconds}`
    }
    return '00:00'
}