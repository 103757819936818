import React, { Suspense, useEffect, useState } from 'react'
import { Box, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { drugAllergyStatus, getListAllergies, getPatient } from 'services/Patient/patient'
import Routes from 'constants/routes'
import { formatDateTime } from 'utilities/formats'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'

import { Card } from '../view/CardHealthRecord'
import { usePatientDashboardStyles } from '../style'
import { Allergy } from '../interfaces'
import { AllergiesForm } from '../view/Allergies/NewAllergy'
import { NoData } from './NoData'
import { stringConcatenation } from 'shared/utils'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { getPatientDetails } from 'store/actions/patient'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
    patientId?: string
    encounter_id?: string,
    patientData: Object
}

export const Allergies = (props: Props) => {
    const classes = usePatientDashboardStyles()
    const [patientId, setPatientId] = useState('')
    const [bundleId, setBundleId] = useState('')
    const [allergyActive, setallergyActive] = useState(false)

    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [allergies, setAllergies] = useState<Allergy[]>([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [flag, setFlag] = useState<boolean>(false)
    const [patientData, setPatientData] = useState<any>()
    const [paging] = useState<{
        page: number
        pageSize: number
    }>({
        page: 1,
        pageSize: 10,
    })

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            fetchListAllergies()
            patientDetails(patientId)
        }
        setFlag(props?.patientData?.no_allergy || false)
        return () => abortController.abort()
    }, [patientId])

    useEffect(() => {
        setPatientId(props.patientId || id)

    }, [props.patientId, id])

    useEffect(() => {
        if (patientId)
            patientDetails(patientId)
    }, [flag])

    const fetchListAllergies = async () => {
        try {
            setLoading(true)
            const resp = await getListAllergies(patientId, paging.page, paging.pageSize, bundleId)

            if (resp?.data?.bundle_id) setBundleId(resp?.data?.bundle_id)
            setAllergies(resp.data.data as Allergy[])
            activeAllergyCheck(resp.data.data)
        } catch (ex) {
            notification('Cannot get list allergies', 'error')
        }

        setLoading(false)
    }


    useEffect(() => {
        if (patientId) {
            patientDetails(patientId)
            // activeAllergyCheck(allergies)
        }

    }, [allergies])

    const patientDetails = async (id) => {
        try {
            setLoading(true)
            const patientData = await getPatient(id)
            setPatientData(patientData)

            if (patientData) {
                activeAllergyCheck(allergies)
                setFlag(patientData.data?.no_allergy)
            }
        } catch (error) {
            console.error('Error in patientDetails: ', error)
        } finally {
            setLoading(false)
        }
    }
    const activeAllergyCheck = (dataArray = []) => {
        if (allergies.length > 0 && flag === false) {
            setallergyActive(true)
        }
        else {
            setallergyActive(false)
        }

    }

    const allergyStatus = async (e) => {
        setLoading(true)
        await drugAllergyStatus(props?.patientId, { "no_allergy": e.target.checked })
        setFlag(e.target.value)
        setLoading(false)
    }
    const handleViewAll = () => history.push(Routes.PATIENT.ALLERGIES.replace(':id', patientId))

    const Content = () => (
        <>
            {!flag && allergies.map((item, key) => {
                const brandName = item?.allergy?.brandName
                const genericName = item?.allergy?.genericName
                const strength = item?.allergy?.strength
                const form = item?.allergy?.form
                const showText = stringConcatenation(brandName, genericName, strength, form)
                return (
                    <Grid
                        container
                        key={key}
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        style={{
                            borderBottom: '1px solid #E4E8EE',
                            paddingBottom: 8,
                            height: 'fit-content',
                        }}>
                        <Grid
                            item
                            container
                            alignItems="flex-start"
                            direction="column"
                            style={{
                                width: 'fit-content',
                            }}>
                            <Typography>
                                {showText ||
                                    item.allergy?.coding.find((item) => item.display === 'NCDID')
                                        ?.code /* FIXME: */ ||
                                    item.reaction?.display}
                            </Typography>
                            <Typography
                                style={{
                                    color: '#9CA9B8',
                                    fontSize: 12,
                                }}>
                                {!!item.onset_date &&
                                    formatDateTime(item.onset_date, 'MMM DD, YYYY')}
                            </Typography>
                        </Grid>
                        <Typography className={classes[`tag_type_${item.status?.code}`]}>
                            {item.status?.code}
                        </Typography>
                    </Grid>
                )
            })}

            {!!allergies.length && !flag && (
                <Grid container item justifyContent="flex-end">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            // menu={[
            //     {
            //         name: 'Add',
            //         handler: () => setIsAddingNew(true),
            //     },
            //     {
            //         name: 'View all',
            //         handler: props.viewAllFunc || handleViewAll,
            //     },
            // ]}
            viewAllHandler={props?.viewAllFunc || handleViewAll}
            addHandler={() => setIsAddingNew(true)}
            onClick={props.onClick}
            header="Medication Allergies"
        >
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Box style={{ display: "flex", padding: "7px", marginleft: 15, borderBottom: '1px solid #E4E8EE' }}>
                    <AntSwitch onChange={allergyStatus} checked={flag} disabled={allergyActive || loading ? true : false} /><span style={{ fontSize: "13px", paddingLeft: "5px" }}>No known drug for allergies</span>

                </Box>
                <Box style={{ paddingRight: "13px", marginBottom: "10px" }}>
                    {/* <Divider variant="middle" /> */}
                </Box>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        padding: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    {flag && <Grid
                        container
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        style={{
                            borderBottom: '1px solid #E4E8EE',
                            padding: 15,
                            height: 'fit-content',
                        }}>
                        <Grid
                            item
                            container
                            alignItems="flex-start"
                            direction="column"
                            style={{
                                width: 'fit-content',
                            }}>
                            <Typography>
                                NKDA
                            </Typography>
                        </Grid>
                        <Typography className={classes[`tag_type_active`]}>
                            Active
                        </Typography>
                    </Grid>}
                    <Loading visible={loading} />
                    {allergies.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>

            {!!isAddingNew && (
                <AllergiesForm
                    encounter_id={props.encounter_id}
                    patientId={patientId}
                    open={isAddingNew}
                    onClose={(forceReload) => {
                        setIsAddingNew(false)

                        if (forceReload) setTimeout(() => fetchListAllergies(), 1000)
                    }}
                />
            )}
        </Card>
    )
}
