import {
    Grid,
    List,
    Typography,
} from '@material-ui/core'
import classNames from 'classnames/bind'
import moment from 'moment'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ErrorIcon from '../../../assests/icons/error-icon.svg'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import localforage from 'localforage'
import {
    setSelectedEncounter,
    handleGetEncounterQueueList,
    handleChangeEncounterStatus,
    saveObjectEncouterStart,
    setEncounterMeetingId,
    setEncounterRoom,
    updateEncounterQueueList,
    removeEncounterQueueList,
    updateEncounterAppointmentStatus,
    addEncounterQueueList,
    updateStatusEncounterQueueList,
    updateStatusParticipant,
    setLastMessage,
    setVonageProviderSession,
} from '../../../store/actions/provider'
import SpinnerIcon from '../../../assests/icons/SpinnerIcon'
import { ButtonSpinnerEncounter } from '../../../components/custom'
import { examEndApi } from '../../../services/Provider/provider'
import {
    ENCOUNTER_STATUS,
    ENCOUNTER_QUEUE_PATIENT_STATUS,
    ENCOUNTER_QUEUE_PATIENT_STATUS_LIST,
    PATIENT_STATUS,
} from '../constant'
import { examAllowPatientJoinApi } from '../../../services/Provider/provider'
import ErrorDialog from '../../../components/custom/ErrorModal'
import { SET_ENCOUNTER, SET_TELEHEALTH_SCREEN } from 'store/actionType'
import { TAB_CLINICALNOTE } from 'constants/menu'
import { ReactComponent as NoClinicalNoteIcon } from '../../../assests/icons/NoClinicalNote.svg'
import MapIcon from '../../../assests/icons/map-icon.svg'
import { isObjectNull, shortenString } from '../utils'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { postStatusChangeRequest } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import Routes from 'constants/routes'
import { isEmpty, set, debounce } from 'lodash'
import { config } from '../../../constants'
import useRoom from '../../../components/Telehealth/hooks/useRoom'
import { usePageVisibility } from 'shared/hooks/usePageVisibility'
import { useTimezone } from 'shared/hooks/useTimezone'
import {
    handleOpenSaveAndCloseDialog,
    handleSetCurrentEncounter,
    handleVisibleSaveAndClose,
    setAllowError,
} from 'store/actions/clinicalNotes'
import ProviderLate from '../../../assests/icons/ProviderLate.svg'
import ProviderLateActive from '../../../assests/icons/ProviderLateActive.svg'
import { providerRunninglate } from 'services/ProviderDirectory'
import { sync30seconds } from 'utilities/sync30seconds'
import EncounterItem  from './EncounterItem'
import { useStyles } from './EncounterItem/styles'
import LazyLoader from '../../../components/LazyLoader'

const EncounterList = (props) => {
    const dispatch = useDispatch()
    const dateFilterEq = useSelector((state) => state.provider.encounterQueueListDate)
    const encounterRoom = useSelector((state) => state.provider.encounterRoom)
    const { room, leaveRoom } = useRoom()
    const history = useHistory()
    const notification = useNotification()
    const me = useSelector((state) => state.login.userData)
    let { encounterQueueList } = props
    const providerId = localStorage.getItem('userId')
    const link = `${config.dev.baseUrlSocket}?type=practitioner????${providerId}`
    const socketPage = ['/schedule', '/consultation']
    const [encounterQueueListData, setencounterQueueList] = useState([...encounterQueueList])

    const isPageVisible = usePageVisibility()
    const timerIdRef = useRef([])
    const [isPollingEnabled, setIsPollingEnabled] = useState(true)
    const [loadingApi, setLoadingApi] = useState(false)
    const [encounterId, setEncounterId] = useState(null)

    const { locationTimezone } = useTimezone()

    // const isConsultant = useSelector((state) => state?.provider?.objectEncouterStart)
    const telehealthScreen = useSelector((state) => state.telehealthScreen)

    const lastMessage = null
    const connectionStatus = 'Uninstantiated'
    const [firstTime, setFirstTime] = useState(true)

    const { saveAndCloseDialog, allowError } = useSelector((state) => state.clinicalNotes)

    useEffect(()=>{
        setFirstTime(false)
    },[])
    const debouncedRefetchEncountersRef = useRef(debounce(() => props.refetchEncounters(), 2000))
    const [isActiveTab, setIsActiveTab] = useState(true);
    const intervalRef = useRef(null);
    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            setIsActiveTab(true);
        } else {
            setIsActiveTab(false);
        }
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // We use the firstTime state to avoid triggering certain actions during the initial render of the page.
        if(isActiveTab && !firstTime){
            props.refetchEncounters()
        }
        if (isActiveTab && !intervalRef.current) {
            intervalRef.current = setInterval(() => {
                debouncedRefetchEncountersRef.current();
            }, 30000); // 30 seconds
        }
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [isActiveTab]);

    const markedProviderGettingLate = useCallback(async (id, encounterid) => {
        try {
            setLoadingApi(true)
            setEncounterId(encounterid)
            const res = await providerRunninglate(id)
            if (res) {
                props.refetchEncounters({ forceUpdate: true })
            }
            setTimeout(() => {
                setLoadingApi(false)
                setEncounterId(null)
            }, 2000)
        } catch (err) {
            setEncounterId(null)
            setLoadingApi(false)
            console.error('Error data:', err)
        }
    },[props.refetchEncounters])

    const encounterQueueListEncouter = useSelector((state) => state.provider.encounterQueueList)

    useEffect(() => {
        console.log('71=>last Message: ', lastMessage)
        if (!isEmpty(lastMessage) && lastMessage?.data !== 'check') {
            const messageSocket = JSON.parse(lastMessage?.data)
            dispatch(setLastMessage(messageSocket))
            switch (messageSocket.action) {
                case 'waiting_room_member':
                    dispatch(updateStatusParticipant(messageSocket, encounterQueueListEncouter))
                    dispatch(updateStatusEncounterQueueList(messageSocket))
                    break
                case 'exam_room_member':
                case 'waiting_room_disconnected':
                case 'exam_room_disconnected':
                case 'encounter_status':
                    dispatch(updateStatusEncounterQueueList(messageSocket))
                    break
                case 'encounter_add':
                    dispatch(addEncounterQueueList(messageSocket.data))
                    break
                case 'appointment_status':
                    dispatch(updateEncounterAppointmentStatus(messageSocket))
                    break
                case 'encounter_remove':
                    dispatch(removeEncounterQueueList(messageSocket))
                    break
                case 'encounter_update':
                    dispatch(updateEncounterQueueList(messageSocket))
                    break
                default:
                    break
            }
        }
    }, [lastMessage?.data])
    if (encounterQueueList !== undefined) {
        encounterQueueList = encounterQueueList.map((encounter) => {
            // CARD STATUS

            // If the patient left the call because of disconnection. Set card status to ''.
            if (
                encounter.status === ENCOUNTER_STATUS.NOT_START ||
                (encounter?.patient_status === PATIENT_STATUS.DISCONNECT_WAITING_ROOM &&
                    encounter.status === ENCOUNTER_STATUS.EXAM_WAITING)
            ) {
                encounter.CARD_STATUS = PATIENT_STATUS.NOT_START
            } else if (
                (encounter.status === ENCOUNTER_STATUS.PRE_EXAM_WAITING &&
                    encounter.patient_status === PATIENT_STATUS.IN_WAITING_ROOM) ||
                (encounter.status === ENCOUNTER_STATUS.EXAM_WAITING &&
                    encounter.patient_status === PATIENT_STATUS.IN_WAITING_ROOM)
            ) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED
            } else if (
                encounter.status === ENCOUNTER_STATUS.NO_SHOW &&
                encounter.patient_status === PATIENT_STATUS.NO_SHOW
            ) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW
            } else if (
                encounter.status === ENCOUNTER_STATUS.COMPLETED &&
                encounter.patient_status === PATIENT_STATUS.COMPLETED
            ) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED
            } else {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION
            }

            // refactored status check
            if (encounter.status === ENCOUNTER_STATUS.EXAM_WAITING) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED
            }

            // // LOGGED IN USER ACTIVE STATUS
            // if ('log_entries' in encounter && encounter.log_entries.length !== 0) {
            //     encounter.log_entries.forEach((logInfo) => {
            //         if (logInfo.created_by === me['custom:user_id']) {
            //             encounter.USER_ACTIVE_STATUS = true
            //             return false
            //         }
            //         encounter.USER_ACTIVE_STATUS = false
            //         return true
            //     })
            // } else {
            //     encounter.USER_ACTIVE_STATUS = false
            // }
            // if (encounter.USER_ACTIVE_STATUS) {
            //     setUserLoggedInEncounter(encounter.encounter_id)
            // }
            return encounter
        })
    }
    const [loading, setLoading] = useState(false)
    const encounterMeetingId = useSelector((state) => state.provider.encounterMeetingId)
    const selectedEncounter = useSelector((state) => state.provider.selectedEncounter)
    const shedulePermission = useSelector((state) => state.permission?.scheduling)
    // console.log('selectedEncounter', selectedEncounter)
    const openConsultationPage = (item) => {
        let method = (id) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({})
                }, 800)
            })
        }

        if (
            item.status === ENCOUNTER_STATUS.EXAM_WAITING &&
            item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM
        ) {
            method = examAllowPatientJoinApi
        }

        setLoading(true)
        dispatch(setSelectedEncounter(item))
        dispatch(setEncounterRoom(null))

        method(item.encounter_id)
            .then(() => {
                dispatch(setEncounterMeetingId(item.encounter_id))
                dispatch(handleGetEncounterQueueList())
                dispatch({
                    type: SET_TELEHEALTH_SCREEN,
                    active: true,
                    tabName: TAB_CLINICALNOTE,
                })
                // history.push('/consultation')
                if (item?.clinical_note_id !== '') history.push('/consultation')
                else history.push('/schedule')
            })
            .catch((error) => {
                dispatch(setAllowError(true))
            })
            .finally(() => {
                setLoading(false)
                // dispatch(setSelectedEncounter(null))
            })
    }
    const openConsultaionPageWioutVideoCall = (item) => {
        try {
            setLoading(true)
            dispatch(setSelectedEncounter(item))
            dispatch(saveObjectEncouterStart(item))
            dispatch(setEncounterMeetingId(item.encounter_id))
            const payload = {
                id: item.encounter_id,
                status: ENCOUNTER_STATUS.EXAM,
                patient_status: PATIENT_STATUS.EXAM,
            }
            dispatch(handleChangeEncounterStatus(payload))
        } catch (error) {
            dispatch(setAllowError(true))
        } finally {
            setLoading(false)
            if (item?.clinical_note_id !== '') {
                history.push(Routes.WORK_QUEUES.CLINICAL_NOTES.NEW_NOTE, {
                    id: item?.clinical_note_id,
                })
            } else history.push(Routes.SCHEDULE)
        }
    }

    const classes = useStyles()
    const cx = classNames.bind(classes)

    const encounterUserActiveStatus = () => {}
    const renderText = (status) => {
        switch (status) {
            case ENCOUNTER_STATUS.EXAM_WAITING:
                return 'Start'
            case ENCOUNTER_STATUS.EXAM:
                return 'Join'
            default:
                return 'Start'
        }
    }

    const canShowEncounter = (encounter, user) => {
        if (encounter.status === ENCOUNTER_STATUS.COMPLETED) {
            return false
        }
        // console.log('encounter details', encounter)
        const cx = isShowStartBtn(encounter)
        return cx
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [encounterListItem, setEncounterListItem] = useState({})

    const handleOpenAlertPopup = useCallback((event, item, index) => {
        const selectElement = document.getElementById(`${index}`)
        setAnchorEl(selectElement)
        setEncounterListItem(item)
    }, [])

    const handleCloseAlertPopup = () => {
        setAnchorEl(null)
        setEncounterListItem({})
    }

    const handleCloseAlertPopupCustom = (closeEvent = '') => {
        handleEncounterCardStatus(encounterListItem, 'NO_SHOW', closeEvent)
        handleCloseAlertPopup()
    }

    // const setStatusLoadingFalse = () => setStatusLoading(false)
    // const setStatusLoadingTrue = () => setStatusLoading(true)

    const onSuccess = () => {
        postStatusChangeRequest('noshow', encounterListItem.appointment_id, null, locationTimezone)
        handleCloseAlertPopupCustom('success')
    }

    const handleEncounterCardStatus = useCallback((encounter, status, closeEvent = '') => {
        setTimeout(() => {
            props?.refetchSchedule && props?.refetchSchedule(dateFilterEq)
        }, 10000)
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.EXAM_WAITING,
                patient_status: PATIENT_STATUS.IN_WAITING_ROOM,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(handleChangeEncounterStatus(payload))
        }
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.NO_SHOW,
                patient_status: PATIENT_STATUS.NO_SHOW,
                is_delete_note: closeEvent === 'success' ? true : false,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(
                // handleChangeEncounterStatus(payload, setStatusLoadingTrue, setStatusLoadingFalse),
                handleChangeEncounterStatus(payload),
            )
        }
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.NOT_START) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.NOT_START,
                patient_status: PATIENT_STATUS.NOT_START,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(handleChangeEncounterStatus(payload))
        }
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.COMPLETED,
                patient_status: PATIENT_STATUS.COMPLETED,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(handleChangeEncounterStatus(payload))
        }
    }, [props?.refetchSchedule, props?.refetchSchedule])

    const handleStartEncounter = useCallback((encounter) => {
        if (encounter.appointment_type !== 'Office Visit') {
            dispatch({
                type: SET_ENCOUNTER,
                encounter_id: encounter.encounter_id,
            })
            handleEncounterCardStatus(encounter, ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION)
            openConsultationPage(encounter)
        } else {
            dispatch({
                type: SET_ENCOUNTER,
                encounter_id: encounter.encounter_id,
            })
            handleEncounterCardStatus(encounter, ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION)
            openConsultaionPageWioutVideoCall(encounter)
        }
    }, [])

    const handleJoinEncounter = useCallback((encounter) => {
        handleStartEncounter(encounter)
    }, [])

    const endMeeting = async (item, cb) => {
        setLoading('end')
        dispatch(setSelectedEncounter(item))
        const sessionData = {
            participants: encounterRoom?.participants,
            roomId: encounterRoom?.roomId,
        }
        examEndApi(item?.encounter_id, sessionData)
            .then((response) => {
                if (response) {
                    handleEndClose()
                    dispatch(setEncounterMeetingId(null))
                    dispatch(setEncounterRoom(null))
                    setLoading('')
                    dispatch(setSelectedEncounter(null))
                    dispatch(
                        handleVisibleSaveAndClose({
                            [item?.encounter_id]: true,
                        }),
                    )
                    dispatch(
                        handleOpenSaveAndCloseDialog({
                            [item?.encounter_id]: false,
                        }),
                    )
                    dispatch(handleSetCurrentEncounter({}))
                    dispatch(setVonageProviderSession({}))
                }
            })
            .catch((excp) => {
                console.log(excp)
            })
            .finally(async () => {
                cb && cb()
            })
    }

    const quit = (item) => {
        setLoading('quit')
        dispatch(setSelectedEncounter(item))
        encounterRoom?.leave()
        setEncounterRoom(null)
        setLoading('')
        dispatch(setSelectedEncounter(null))
        // history.push('/schedule')
        window.location.assign('/schedule')
    }

    const handleEndMeeting = (encounter, cb) => {
        endMeeting(encounter, cb)
        dispatch({
            type: SET_TELEHEALTH_SCREEN,
            active: false,
            tabName: '',
        })
    }

    const isEndBtn = useCallback((item) => {
        // return [ENCOUNTER_STATUS.EXAM, ENCOUNTER_STATUS.POST_EXAM, ENCOUNTER_STATUS.PRE_EXAM].includes(item.status) &&
        return !item.encounter_id === encounterMeetingId
    }, [encounterMeetingId])

    const isQuitBtn = useCallback((item) => {
        // return [ENCOUNTER_STATUS.EXAM].includes(item.status) &&
        // [PATIENT_STATUS.PRE_EXAM, PATIENT_STATUS.IN_WAITING_ROOM].includes(item.patient_status)
        // && (item.encounter_id === encounterMeetingId)
        return item.encounter_id === encounterMeetingId
    }, [encounterMeetingId])

    const handleDisableStatusForEndEncounter = (encounter) => {
        if (encounter.appointment_type === 'Office') {
            return true
        }
        if (me.role_code === 'medical_provider') {
            return true
        }
        return true
    }

    const isShowStartBtn = (item) => {
        return (
            (item.status === ENCOUNTER_STATUS.EXAM_WAITING &&
                item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM) ||
            (item.status === ENCOUNTER_STATUS.EXAM &&
                item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM) ||
            (item.status === ENCOUNTER_STATUS.EXAM && item.patient_status === PATIENT_STATUS.EXAM)
        )
    }

    const handleEndEncounter = (encounter) => {}

    const handleLeaveEncounter = useCallback((encounter) => {
        quit(encounter)
        dispatch({
            type: SET_TELEHEALTH_SCREEN,
            active: false,
            tabName: '',
        })
    }, [])

    const [anchorEndEl, setAnchorEndEl] = React.useState(null)

    const [encounterToDelete, setEncounterToDelete] = useState(null)

    const handleEndClick = useCallback((event, item) => {
        console.log(event.currentTarget)
        console.log(item)
        setEncounterToDelete(item)
        setAnchorEndEl(event.currentTarget)
    }, [])

    const handleEndClose = () => {
        setAnchorEndEl(null)
    }

    const openEnd = Boolean(anchorEndEl)
    const idEnd = openEnd ? 'cstm-simple-popover' : undefined
    // if (statusLoading) {
    //     notification("Don't refresh the page while the status is being changed", 'warning')
    // }

    const timestampDecreser = (timestamp) => {
        const genTimestamp = `${timestamp}:00`
        let [minutes, seconds] = genTimestamp.split(':').map(Number)
        if (seconds === 0) {
            minutes = minutes - 1
            seconds = 59
        } else {
            seconds = seconds - 1
        }

        const totalminutes = minutes + seconds / 60

        return totalminutes
    }

    useEffect(() => {
        localforage.config({
            driver: localforage.INDEXEDDB, // Use IndexedDB as the storage driver
            name: 'myApp', // Name of the database
            version: 1, // Database version
            storeName: 'InsessionEncounter', // Name of the object store
        })

        const addDataToIndexedDB = async (key, value) => {
            try {
                await localforage.setItem(key, value)
            } catch (error) {
                console.error('Error adding data:', error)
            }
        }

        const getDataFromIndexedDB = async (key) => {
            try {
                const data = await localforage.getItem(key)
                return data
            } catch (error) {
                console.error('Error retrieving data:', error)
            }
        }

        const deleteAllDataFromIndexedDB = async () => {
            try {
                await localforage.clear()
            } catch (error) {
                console.error('Error deleting all data:', error)
            }
        }

        const deleteDataFromIndexedDB = async (key) => {
            try {
                await localforage.removeItem(key)
            } catch (error) {
                console.error('Error deleting data:', error)
            }
        }

       const intervalId = setInterval(async () => {
            const startedCall = []
            const cloned = [...encounterQueueList]

            const data = cloned.map((item, idx) => {
                if (item.CARD_STATUS === 'IN_SESSION') {
                    const setterItem = {
                        ...item,
                        duration: timestampDecreser((moment(item.end).valueOf() - Date.now()) / 60000),
                    }
                    item = setterItem
                }
                return item
            })

            if (data.length) {
                await deleteDataFromIndexedDB('InsessionEncounter')
                await addDataToIndexedDB('InsessionEncounter', JSON.stringify(data))

                setencounterQueueList(data)
            }
        }, 1000)

        return () => {
            clearInterval(intervalId)
        }
    }, [encounterQueueList])

    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            className={classes.encounterListWrapper}
            container
        >
            <div className={classes.wrapList}>
                <List style={{ height: '100%' }}>
                    <LazyLoader dividerComponent={'li'} >
                    {encounterQueueListData &&
                        encounterQueueListData
                            .sort((encounter1, encounter2) => {
                                return moment(encounter1.start) - moment(encounter2.start)
                            })
                            .map((item, index) => {
                                return (
                                    <EncounterItem 
                                        key={index}
                                        index={index}
                                        item={item}
                                        loadingApi={loadingApi}
                                        encounterId={encounterId} 
                                        shedulePermission={shedulePermission}
                                        markedProviderGettingLate={markedProviderGettingLate}
                                        handleOpenAlertPopup={handleOpenAlertPopup}
                                        handleEncounterCardStatus={handleEncounterCardStatus}
                                        telehealthScreen={telehealthScreen}
                                        selectedEncounter={selectedEncounter}
                                        saveAndCloseDialog={saveAndCloseDialog}
                                        loading={loading}
                                        handleEndClick={handleEndClick}
                                        handleStartEncounter={handleStartEncounter}
                                        handleLeaveEncounter={handleLeaveEncounter}
                                        isEndBtn={isEndBtn}
                                        handleJoinEncounter={handleJoinEncounter}
                                        isQuitBtn={isQuitBtn}
                                        refetchEncounters={props.refetchEncounters}
                                    />
                                )
                            })}
                    </LazyLoader>
                </List>
            </div>
            <ErrorDialog open={allowError} handleClose={() => dispatch(setAllowError(false))} />
            <Popover
                id={idEnd}
                open={openEnd}
                anchorEl={anchorEndEl}
                onClose={handleEndClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="popover_container">
                    <div className="heading_holder">
                        <img src={ErrorIcon}></img>
                        <h2 className="heading">End Encounter</h2>
                    </div>
                    <Typography className="content">
                        Are you sure to end this encounter ?
                    </Typography>
                    <div className="btn_holder">
                        <Button className="no_bg" onClick={handleEndClose}>
                            Cancel
                        </Button>
                        <Button
                            className="error"
                            onClick={() => {
                                handleEndMeeting(encounterToDelete, () =>
                                    props.refetchEncounters({ forceUpdate: true }),
                                )
                            }}>
                            End
                        </Button>
                    </div>
                </div>
            </Popover>
            <CustomAlertPopOver
                anchorEl={anchorEl}
                handleClose={handleCloseAlertPopup}
                disableOutsideClose={true}
                customHandleClose={handleCloseAlertPopupCustom}
                title={'Clinical Note Deletion'}
                bodyText={`Are you sure you want to delete the clinical note associated with this appointment?`}
                confirmButtonName={'Delete'}
                cancelButtonName={'Keep'}
                confirmButtonColor={'red'}
                onSuccess={onSuccess}
            />
        </Grid>
    )
}

export default EncounterList
